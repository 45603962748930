import { createAction, props } from '@ngrx/store';
import {
  User,
  CustomizationsSettings,
} from '@models/company.model';
import { Filters, List } from '@models/response.model';

const PREFIX = '[COMPANY]';

export const fetchUsers = createAction(
  `${PREFIX} FETCH_USERS`,
  props<{ filters: Filters }>(),
);
export const fetchUsersSuccess = createAction(
  `${PREFIX} FETCH_USERS_SUCCESS`,
  props<{ userList: List<User> }>(),
);
export const fetchUsersFail = createAction(
  `${PREFIX} FETCH_USERS_FAIL`,
);

export const deactivateUser = createAction(
  `${PREFIX} DEACTIVATE_USERS`,
  props<{ id: number }>(),
);
export const deactivateUserSuccess = createAction(
  `${PREFIX} DEACTIVATE_USERS_SUCCESS`,
);
export const deactivateUserFail = createAction(
  `${PREFIX} DEACTIVATE_USERS_FAIL`,
);

export const activateUser = createAction(
  `${PREFIX} ACTIVATE_USERS`,
  props<{ id: number }>(),
);
export const activateUserSuccess = createAction(
  `${PREFIX} ACTIVATE_USERS_SUCCESS`,
);
export const activateUserFail = createAction(
  `${PREFIX} ACTIVATE_USERS_FAIL`,
);

export const resendActivationLink = createAction(
  `${PREFIX} RESEND_ACTIVATION_LINK`,
  props<{ email: string }>(),
);
export const resendActivationLinkSuccess = createAction(
  `${PREFIX} RESEND_ACTIVATION_LINK_SUCCESS`,
);
export const resendActivationLinkFail = createAction(
  `${PREFIX} RESEND_ACTIVATION_LINK_FAIL`,
);

export const deleteAccount = createAction(
  `${PREFIX} DELETE_ACCOUNT`,
  props<{ id: number }>(),
);
export const deleteAccountSuccess = createAction(
  `${PREFIX} DELETE_ACCOUNT_SUCCESS`,
);
export const deleteAccountFail = createAction(
  `${PREFIX} DELETE_ACCOUNT_FAIL`,
);

export const deleteLearnerAccount = createAction(
  `${PREFIX} DELETE_LEARNER_ACCOUNT`,
);
export const deleteLearnerAccountSuccess = createAction(
  `${PREFIX} DELETE_LEARNER_ACCOUNT_SUCCESS`,
);
export const deleteLearnerAccountFail = createAction(
  `${PREFIX} DELETE_LEARNER_ACCOUNT_FAIL`,
);

export const addUser = createAction(
  `${PREFIX} ADD_USER`,
  props<{ userForm: User }>(),
);
export const addUserSuccess = createAction(
  `${PREFIX} ADD_USER_SUCCESS`,
);
export const addUserFail = createAction(
  `${PREFIX} ADD_USER_FAIL`,
);
export const addUserReset = createAction(
  `${PREFIX} ADD_USER_RESET`,
);

export const editUser = createAction(
  `${PREFIX} EDIT_USER`,
  props<{ id: number, userForm: User }>(),
);
export const editUserSuccess = createAction(
  `${PREFIX} EDIT_USER_SUCCESS`,
);
export const editUserFail = createAction(
  `${PREFIX} EDIT_USER_FAIL`,
);

export const fetchUserDetails = createAction(
  `${PREFIX} FETCH_USER_DETAIL`,
  props<{ id: number }>(),
);
export const fetchUserDetailsSuccess = createAction(
  `${PREFIX} FETCH_USER_DETAIL_SUCCESS`,
  props<{ userDetails: User }>(),
);
export const fetchUserDetailsFail = createAction(
  `${PREFIX} FETCH_USER_DETAIL_FAIL`,
);
export const fetchUserDetailsReset = createAction(
  `${PREFIX} FETCH_USER_DETAIL_RESET`,
);

export const updatePrimaryColor = createAction(
  `${PREFIX} UPDATE_PRIMARY_COLOR`,
  props<{ primaryColor: string }>(),
);
export const updatePrimaryColorSuccess = createAction(
  `${PREFIX} UPDATE_PRIMARY_COLOR_SUCCESS`,
  props<{ primaryColor: string }>(),
);
export const updatePrimaryColorFail = createAction(
  `${PREFIX} UPDATE_PRIMARY_COLOR_FAIL`,
);

export const fetchCustomizationsSettings = createAction(
  `${PREFIX} FETCH_CUSTOMIZATIONS_SETTINGS`,
);
export const fetchCustomizationsSettingsSuccess = createAction(
  `${PREFIX} FETCH_CUSTOMIZATIONS_SETTINGS_SUCCESS`,
  props<{ customizationsSettings: CustomizationsSettings }>(),
);
export const fetchCustomizationsSettingsFail = createAction(
  `${PREFIX} FETCH_CUSTOMIZATIONS_SETTINGS_FAIL`,
);

export const updateLogo = createAction(
  `${PREFIX} UPDATE_LOGO`,
  props<{ logo: string, logoFileName: string }>(),
);
export const updateLogoSuccess = createAction(
  `${PREFIX} UPDATE_LOGO_SUCCESS`,
  props<{ logoUrl: string, logoFileName: string }>(),
);
export const updateLogoFail = createAction(
  `${PREFIX} UPDATE_LOGO_FAIL`,
);

export const deleteLogo = createAction(
  `${PREFIX} DELETE_LOGO`,
);
export const deleteLogoSuccess = createAction(
  `${PREFIX} DELETE_LOGO_SUCCESS`,
);
export const deleteLogoFail = createAction(
  `${PREFIX} DELETE_LOGO_FAIL`,
);
