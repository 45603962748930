export interface Tokens {
  token: string;
  refreshToken: string;
}

export enum TokenType {
  EMAIL_VERIFICATION = 'emailVerificationToken',
  PASSWORD_RESET = 'passwordResetToken'
}

export type RoleValues =
  'accAdmin'
  | 'certManager'
  | 'author'
  | 'proctor'
  | 'externalProctor'
  | 'scorer'
  | 'learner'
  | 'sysAdmin';

export interface Role {
  value: RoleValues,
  description: string,
}

export interface UserData {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  avatar: string;
  organization: {
    name: string,
    id: number,
    reference: string
  };
  testingPlatform: string;
  mobile?: string;
  city?: string;
  country?: string;
}

export interface LoginResponse extends Tokens {
  user: UserData;
}

export interface ForgotPasswordResponse {
  message: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface LoginForm extends LoginCredentials {
  rememberMe: boolean;
}

export interface UserTokenData {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
}

export interface SignUpForm {
  firstName: string,
  lastName: string,
  email: string,
}
