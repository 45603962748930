import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasicErrorDialogComponent } from '@modules/shared/components/error-dialog/basic-error-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class ErrorDialogService {
  constructor(private dialog: MatDialog) { }

  public open(dialogText: string, width?: number): Observable<boolean> {
    return this.dialog.open(BasicErrorDialogComponent, {
      width: `${width || 500}px`,
      data: { dialogText },
      panelClass: 'basic-dialog',
      disableClose: true,
    }).afterClosed();
  }
}
