import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-instant-login-dialog',
  templateUrl: './instant-login-dialog.component.html',
  styleUrls: ['./instant-login-dialog.component.scss'],
})
export class InstantLoginDialogComponent {
  constructor(public dialogRef: MatDialogRef<InstantLoginDialogComponent>) { }

  login() {
    this.dialogRef.close(true);
  }
}
