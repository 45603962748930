import {
  Injectable,
  RendererFactory2,
  Inject,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScriptType } from '@modules/shared/services/scripts/scriptType.enum';
import { scriptMap } from '@modules/shared/services/scripts/scriptMap';

@Injectable({
  providedIn: 'root',
})
export class ScriptInjectorService {
  private renderer: Renderer2;
  private loadedScripts: { [key in ScriptType]: boolean } = {
    FULLSTORY: false,
    PENDO: false,
  };

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public isScriptLoaded(scriptType: ScriptType): boolean {
    return this.loadedScripts[scriptType];
  }

  public loadScript(scriptType: ScriptType): void {
    if (this.loadedScripts[scriptType]) {
      return;
    }

    const scriptTag = this.renderer.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = scriptMap[scriptType];

    this.renderer.appendChild(this.document.body, scriptTag);
    this.loadedScripts[scriptType] = true;
  }
}
