import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers/index';
import { authActions } from '@store/actions';
import { getLoggingIn } from '@store/selectors';
import { LoginForm } from '@models/auth.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent {
  public loggingIn$ = this.store.select(getLoggingIn);

  constructor(
    protected store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
  ) { }

  public handleLogin(loginForm: LoginForm): void {
    const { redirectPath } = this.activatedRoute.snapshot.queryParams;

    this.store.dispatch(authActions.login({ loginForm, redirectPath }));
  }
}
