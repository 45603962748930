/* eslint-disable no-underscore-dangle */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ros-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent {
  @Input() public groupId!: string;
  @Input() public title!: string;
  @Input() public description?: string;
  @Input() public isChecked!: boolean;
  @Input() public value!: string;
  @Input() public isDisabled = false;

  @Output() public handleChange = new EventEmitter<string | null>();

  @ViewChild('input') public input!: ElementRef<HTMLInputElement>;

  public radioId = Math.floor(Math.random() * 1000);

  public onChange(): void {
    this.handleChange.emit(this.input.nativeElement.checked ? this.value : null);
  }
}
