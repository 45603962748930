<div
  class="list-container"
  [ngClass]="{
    'list-container--with-subtitle': !!listSubtitle?.length
  }"
>
  <div class="list-container_titles-wrapper">
    <h4 class="list-container_title">{{ listTitle }}</h4>
    <h5 *ngIf="!!listSubtitle?.length" class="list-container_subtitle">{{ listSubtitle }}</h5>
  </div>

  <div class="list-container_list-items">
    <ng-content></ng-content>
  </div>
</div>
