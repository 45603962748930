import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Icon } from '../../svg-icon/svg-icons';

@Component({
  selector: 'ros-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() public title?: string;
  @Input() public htmlTitle?: string;
  @Input() public subtitle?: string;
  @Input() public htmlSubtitle?: string;
  @Input() public additionalMessage?: string;
  @Input() public isLast?: boolean = false;
  @Input() public isInactive?: boolean = false;
  @Input() public icon: string = Icon.NONE;
  @Input() public primaryColorMessage?: boolean = false;
  @Input() public detailsSubtitle?: [boolean, string, string];

  @Output() public modalOpen = new EventEmitter<void>()

  openModal(): void {
    this.modalOpen.emit()
  }
}
