<div class="id-check">
  <div class="id-check__photo">
    <img
      (click)="onClick(learnerPhotoUrl, learnerPhotoAlt)"
      [src]="learnerPhotoUrl"
      [alt]="learnerPhotoAlt"
    >
  </div>
  <div class="id-check__photo">
    <img
      (click)="onClick(learnerIDPhotoUrl, learnerIdPhotoAlt)"
      [src]="learnerIDPhotoUrl"
      [alt]="learnerIdPhotoAlt"
    >
  </div>
</div>
