import {
  Component,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { SessionState } from '@models/session.model';

@Component({
  selector: 'app-incomplete-session-banner',
  templateUrl: './incomplete-session-banner.component.html',
  styleUrls: ['./incomplete-session-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IncompleteSessionBanner {
  @Input() public sessionState: SessionState;
}
