
   <button
   #toggleElem
   aria-haspopup="true"
   aria-controls="headerDropdown"
   class="context-menu-toggle"
   [attr.aria-expanded]="isVisible"
   [class.context-menu-toggle--active]="isActive"
   [ngClass]="{
     'context-menu-toggle--dark': variant === 'Dark',
     'context-menu-toggle--outline': variant === 'Outline',
     'context-menu-toggle--text-only-style': variant === 'Text-only',
     'context-menu-toggle--filter': variant === 'Filter',
     'context-menu-toggle--expanded': isVisible
   }"
   (click)="toggleContextMenuVisibility()"
 >
   <ng-container *ngIf="htmlTitle; then htmlTitleTemplate else defaultTitleTemplate"></ng-container>
    <div
      class="dropdown"
      id="dropdown"
      #dropdown
      [style.top]="dropdownPosition?.top"
      [style.left]="dropdownPosition?.left"
      [style.right]="dropdownPosition?.right"
      [style.bottom]="dropdownPosition?.bottom"
      (keydown.escape)="onEscapeKeyup.emit()"
      [ngClass]="{
        'dropdown--visible': isVisible
      }"
    >
      <ul
        [id]="dropdownId"
        class="dropdown_list"
        [ngClass]="{
          'dropdown_list--active': isVisible
        }"
        role="grid"
        [attr.aria-labelledby]="ariaLabelledBy"
        data-wrap-rows="true"
        data-wrap-cols="true"
        data-restructure="true">
        <li
          role="row"
          *ngFor="let item of items; let i = index;"
          class="dropdown_list-item"
          [ngClass]="{
            'dropdown_list-item--bold': item.isBold
          }"
        >
        <span role="gridcell">
          <a *ngIf="item.url"
              [id]="'link-'+ i"
              (keydown.arrowdown)="onArrowDown('link')"
              (keydown.arrowup)="onArrowUp('link')"
              class="dropdown_list_link"
              [class.dropdown_list_link--align-left]="textAlign === 'left'"
              tabindex="0"
              (keydown.enter)="onEnterItem(item)"
              (click)="onItemClick(item)">
              {{ item.title }}
          </a>

          <div *ngIf="!item.url"
              [id]="'item-'+ i"
              (keydown.arrowdown)="onArrowDown('item')"
              (keydown.arrowup)="onArrowUp('item')"
              class="dropdown_list_link"
              [class.dropdown_list_link--active]="item.isActive"
              [class.dropdown_list_link--align-left]="textAlign === 'left'"
              tabindex="0"
              (keydown.enter)="onEnterItem(item)"
              (click)="onItemClick(item)">
            {{ item.title }}

            <div class="dropdown_list_link__active-check" *ngIf="item.isActive">

            </div>
          </div>

        </span>
        </li>
      </ul>
    </div>
</button>

<!-- Default title template -->
<ng-template #defaultTitleTemplate>
  <span class="header_context-menu-title">{{ title }}</span>
</ng-template>

<!-- HTML Title template -->
<ng-template #htmlTitleTemplate>
  <span
    class="header_context-menu-title"
    [innerHTML]="htmlTitle"
  ></span>
</ng-template>
