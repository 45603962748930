import { Injectable, OnDestroy } from '@angular/core';
import {
  NavigationEnd, Router, RouterEvent,
} from '@angular/router';
import { Store } from '@ngrx/store';
// import { fetchCustomizationsSettings } from '@store/actions/company.actions';
// import { getCustomizationsSettings } from '@store/selectors';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const ROSALYN_BLUE = '#1b65e2';

@Injectable({
  providedIn: 'root',
})
export class ThemingService implements OnDestroy {
  // private customizationSettings$ = this.store.select(getCustomizationsSettings);
  private ngDestroy$ = new Subject();
  private primaryColor = ROSALYN_BLUE;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) { }

  public ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public async initialize(): Promise<void> {
    this.router.events.pipe(
      filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
    )
      .subscribe(async(routerEvent: NavigationEnd) => {
        if (routerEvent.url.startsWith('/admin') || routerEvent.url.startsWith('/forgot-password')) {
          this.setColorVariable(this.primaryColor);
          return Promise.resolve();
        }

        // await this.setCustomColor();
      });
  }

  // private setCustomColor(): Promise<void> {
  //   return new Promise((resolve) => {
  //     this.store.dispatch((fetchCustomizationsSettings()));

  //     this.customizationSettings$.pipe(
  //       filter((value) => !!value),
  //     )
  //       .subscribe((settings) => {
  //         if (settings.primaryColor) {
  //           this.primaryColor = settings.primaryColor;
  //         }

  //         this.setColorVariable(this.primaryColor);

  //         return resolve();
  //       });
  //   });
  // }

  private setColorVariable(color: string): void {
    document.documentElement.style.setProperty('--primary-color', color);
  }
}
