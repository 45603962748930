<div class="basic-dialog-container">
  <div mat-dialog-content>
    <p class="text">{{data.dialogText}}</p>
  </div>
  <div
    mat-dialog-actions
    class="buttons"
  >
    <button
      mat-stroked-button
      (click)="agree()"
      class="flat-button"
    >
      {{data.buttonText}}
    </button>
  </div>
</div>
