import { createAction, props } from '@ngrx/store';
import { Filters, List } from '@models/response.model';
import {
  MyAssessment, ProductDetails, Products, ProductDetailsExam,
} from '@models/learners.model';
import { Country } from '@models/misc.model';

const PREFIX = '[LEARNERS]';

export const fetchMyAssessments = createAction(
  `${PREFIX} FETCH_MY_ASSESSMENTS`,
  props<{ filters: Filters }>(),
);
export const fetchMyAssessmentsSuccess = createAction(
  `${PREFIX} FETCH_MY_ASSESSMENTS_SUCCESS`,
  props<{ assessments: List<MyAssessment> }>(),
);
export const fetchMyAssessmentsFail = createAction(
  `${PREFIX} FETCH_MY_ASSESSMENTS_FAIL`,
);

export const fetchCountries = createAction(
  `${PREFIX} FETCH_COUNTRIES`,
);
export const fetchCountriesSuccess = createAction(
  `${PREFIX} FETCH_COUNTRIES_SUCCESS`,
  props<{ countries: Country[] }>(),
);
export const fetchCountriesFail = createAction(
  `${PREFIX} FETCH_COUNTRIES_FAIL`,
);

export const fetchProducts = createAction(
  `${PREFIX} FETCH_PRODUCTS`,
  props<{ categoryId: number, subcategoryId?: number }>(),
);
export const fetchProductsSuccess = createAction(
  `${PREFIX} FETCH_PRODUCTS_SUCCESS`,
  props<{ products: Products[] }>(),
);
export const fetchProductsFail = createAction(
  `${PREFIX} FETCH_PRODUCTS_FAIL`,
);

export const fetchProductDetails = createAction(
  `${PREFIX} FETCH_PRODUCT`,
  props<{ reference: string }>(),
);
export const fetchProductDetailsSuccess = createAction(
  `${PREFIX} FETCH_PRODUCT_SUCCESS`,
  props<{ productDetails: ProductDetails }>(),
);
export const fetchProductDetailsFail = createAction(
  `${PREFIX} FETCH_PRODUCT_FAIL`,
);

export const buyProduct = createAction(
  `${PREFIX} BUY_PRODUCT`,
  props<{ reference: string }>(),
);
export const buyProductSuccess = createAction(
  `${PREFIX} BUY_PRODUCT_SUCCESS`,
);
export const buyProductFail = createAction(
  `${PREFIX} BUY_PRODUCT_FAIL`,
);

export const addProductToCart = createAction(
  `${PREFIX} ADD_PRODUCT_TO_CART`,
  props<{ productId: ProductDetails['id'], examIds: ProductDetailsExam['id'][] }>(),
);

export const addProductToCartSuccess = createAction(
  `${PREFIX} ADD_PRODUCT_TO_CART_SUCCESS`,
  props<{ productId: ProductDetails['id'], examIds: ProductDetailsExam['id'][] }>(),
);

export const addProductToCartFail = createAction(
  `${PREFIX} ADD_PRODUCT_TO_CART_FAIL`,
);
