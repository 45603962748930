import { Action, createReducer, on } from '@ngrx/store';
import {
  MyAssessment,
  Category,
  ProductDetails,
  Products,
  Cart,
} from '@models/learners.model';
import { Country } from '@models/misc.model';
import { learnersActions } from '../actions';

export interface LearnersState {
  myAssessments: MyAssessment[];
  myAssessmentsFetching: boolean;
  countries: Country[];
  countriesFetching: boolean;
  categories: Category[];
  categoriesFetching: boolean;
  productDetails: ProductDetails;
  productDetailsFetching: boolean;
  productBuying: boolean;
  productsFetching: boolean;
  products: Products[];
  cart: Cart;
}

export const initialState: LearnersState = {
  myAssessments: null,
  myAssessmentsFetching: false,
  countries: null,
  countriesFetching: false,
  categories: null,
  categoriesFetching: false,
  productDetails: null,
  productDetailsFetching: false,
  productBuying: false,
  products: null,
  productsFetching: false,
  cart: {
    products: {},
  },
};

const reducer = createReducer(
  initialState,
  on(learnersActions.fetchMyAssessments,
    (state) => ({
      ...state,
      myAssessmentsFetching: true,
    })),
  on(learnersActions.fetchMyAssessmentsSuccess,
    (state, action) => ({
      ...state,
      myAssessmentsFetching: false,
      myAssessments: action.assessments.list,
    })),
  on(learnersActions.fetchMyAssessmentsFail,
    (state) => ({
      ...state,
      myAssessmentsFetching: false,
    })),

  on(learnersActions.fetchCountries,
    (state) => ({
      ...state,
      countriesFetching: true,
    })),
  on(learnersActions.fetchCountriesSuccess,
    (state, action) => ({
      ...state,
      countriesFetching: false,
      countries: action.countries,
    })),
  on(learnersActions.fetchCountriesFail,
    (state) => ({
      ...state,
      countriesFetching: false,
    })),

  on(learnersActions.fetchProducts,
    (state) => ({
      ...state,
      productsFetching: true,
    })),
  on(learnersActions.fetchProductsSuccess,
    (state, action) => ({
      ...state,
      productsFetching: false,
      products: action.products,
    })),
  on(learnersActions.fetchProductsFail,
    (state) => ({
      ...state,
      productsFetching: false,
    })),

  on(learnersActions.fetchProductDetails,
    (state) => ({
      ...state,
      productDetailsFetching: true,
    })),
  on(learnersActions.fetchProductDetailsSuccess,
    (state, action) => ({
      ...state,
      productDetails: action.productDetails,
      productDetailsFetching: false,
    })),
  on(learnersActions.fetchProductDetailsFail,
    (state) => ({
      ...state,
      productDetailsFetching: false,
    })),

  on(learnersActions.buyProduct,
    (state) => ({
      ...state,
      productBuying: true,
    })),
  on(learnersActions.buyProductSuccess,
    (state) => ({
      ...state,
      productBuying: false,
    })),
  on(learnersActions.buyProductFail,
    (state) => ({
      ...state,
      productBuying: false,
    })),

  on(learnersActions.addProductToCartSuccess,
    (state, { examIds, productId }) => {
      if (!examIds.length) {
        return state;
      }

      return ({
        ...state,
        cart: {
          ...state.cart,
          products: {
            ...state.cart.products,
            [productId]: examIds,
          },
        },
      });
    }),
);

export function learnersReducer(state: LearnersState | undefined, action: Action): LearnersState {
  return reducer(state, action);
}
