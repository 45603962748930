<div class="radio-button" [ngClass]="{
    'radio-button--disabled': isDisabled
  }">
  <input
    #input
    class="radio-button_input"
    type="radio"
    [attr.id]="radioId"
    [attr.name]="groupId"
    [attr.value]="value"
    [checked]="isChecked"
    (change)="onChange()"
  />
  <label class="radio-button_label" [for]="radioId">
    <span class="radio-button_toggle">
      <svg class="radio-button_toggle-checkmark-svg">
        <use href="assets/icons/checkmark.svg#checkmark"></use>
      </svg>
    </span>
    <div class="radio-button_text-container">
      <span class="radio-button_title">{{ title }}</span>
      <span class="radio-button_description">{{ description }}</span>
    </div>
  </label>
</div>
