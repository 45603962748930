import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-severity-icon',
  templateUrl: './severity-icon.component.html',
  styleUrls: ['./severity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeverityIconComponent {
  @Input() public set severityValue(severityValue: string) {
    this.severity = severityValue;
  }

  public severity: string;
}
