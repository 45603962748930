import { ProctoringRules } from './session.model';

export enum EventStatus {
  'ACCEPTED' = 'accepted',
  'REJECTED' = 'rejected',
  'PENDING' = 'pending'
}

export enum MessageCategory {
  'WARNING' = 'warning',
  'ALERT' = 'alert'
}

export enum MessageType {
  SYSTEM = 'system',
  PROCTOR = 'proctor'
}

export interface RelatedExaminee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface NextEventResponse {
  eventId: number | null;
}

export type Severity = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info';

export enum LiveStreamSeekType {
  LIVE = 'live',
  EVENT = 'event',
}

export interface Event {
  confidence: number;
  date: string;
  eventType: string;
  descriptionShort: string;
  id: number;
  sessionId: number;
  severity: Severity;
  status: EventStatus;
  proctorId: number;
  comment?: string;
}

export type EventMode = 'live' | 'post' | 'uploading';

interface StreamDetails {
  tokboxApiKey: string,
  tokboxSessionId: string,
  tokboxToken: string,
}

export interface ProctoringEvent extends Event {
  streamDetails: StreamDetails | null,
  videoSource: string | null,
  mode: EventMode,
  assignedTo: string | null,
  proctoringRules: ProctoringRules
}

export interface Message {
  category?: MessageCategory,
  content: string,
  createdAt: Date,
  type: MessageType,
  eventId: number,
}

export interface DetailedEvent extends ProctoringEvent {
  description: string;
  comment: string;
  commentsToArbiter: string;
  eventStart: number;
  eventEnd: number;
  learnerIDPhotoUrl?: string;
  learnerPhotoUrl?: string;
  examinee: RelatedExaminee;
  messages: Message[];
}
