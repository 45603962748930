import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@modules/shared/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) { }
  confirm(text: string, width: number = 370): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      width: `${width}px`,
      data: { text },
      panelClass: 'confirm-dialog',
      disableClose: true,
    }).afterClosed();
  }
}
