import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from '../reducers/company.reducer';

const getCompanyState = createFeatureSelector<CompanyState>('company');

export const getUserList = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userList,
);

export const getUserListFetching = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userListFetching,
);

export const getDeactivatingUser = createSelector(
  getCompanyState,
  (state: CompanyState) => state.deactivatingUser,
);

export const getActivatingUser = createSelector(
  getCompanyState,
  (state: CompanyState) => state.activatingUser,
);

export const getResendingActivationLink = createSelector(
  getCompanyState,
  (state: CompanyState) => state.resendingActivationLink,
);

export const getDeletingAccount = createSelector(
  getCompanyState,
  (state: CompanyState) => state.deletingAccount,
);

export const getLatestUserListFilters = createSelector(
  getCompanyState,
  (state: CompanyState) => state.lastUserListFilters,
);

export const getUserAdding = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userAdding,
);

export const getUserAdded = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userAdded,
);

export const getUserEditing = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userEditing,
);

export const getUserDetails = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userDetails,
);

export const getUserDetailsFetching = createSelector(
  getCompanyState,
  (state: CompanyState) => state.userDetailsFetching,
);

export const getCustomizationsSettings = createSelector(
  getCompanyState,
  (state: CompanyState) => state.customizationsSettings,
);
