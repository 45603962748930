

<div class="container">
   <div class="title">
    {{dialogData.text}}
   </div>
   <div class="actions">
      <ros-button (onClick)="cancel()"
                  width="MediumShort"
                  variant="Ternary"
                  type="Info"
                  title="No">
      </ros-button>
      <ros-button (onClick)="agree()"
                  width="MediumShort"
                  title="Yes">
      </ros-button>
   </div>
</div>
