import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AuthModel from '@models/auth.model';
import { AuthState } from '../reducers/auth.reducer';

export const getAuthState = createFeatureSelector<AuthState>('auth');

export const getLoggedIn = createSelector(
  getAuthState,
  (state: AuthState) => state.loggedIn,
);

export const getLoggingIn = createSelector(
  getAuthState,
  (state: AuthState) => state.loggingIn,
);

export const getRedirectPath = createSelector(
  getAuthState,
  (state: AuthState) => state.redirectPath,
);

export const getRememberMe = createSelector(
  getAuthState,
  (state: AuthState) => state.rememberMe,
);

export const getUserData = createSelector(
  getAuthState,
  (state: AuthState) => (state.userData),
);

export const getUserId = createSelector(
  getAuthState,
  (state: AuthState) => (state.userData?.id),
);

export const getUserRoles = createSelector(
  getUserData,
  (state: AuthModel.UserData) => (state?.roles),
);

export const getTestingPlatform = createSelector(
  getUserData,
  (state: AuthModel.UserData) => (state ? state.testingPlatform : null),
);

export const getCompany = createSelector(
  getUserData,
  (state: AuthModel.UserData) => (state?.organization?.name),
);

export const getSettingPassword = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.settingPassword : null),
);

export const getChangingPassword = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.changingPassword : null),
);

export const getPasswordChanged = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.passwordChanged : null),
);

export const getSendingRecoveryEmail = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.sendingRecoveryEmail : null),
);

export const getUserTokenData = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.userTokenData : null),
);

export const getUserTokenDataFetching = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.userTokenDataFetching : null),
);

export const getEditingMyAccount = createSelector(
  getAuthState,
  (state: AuthState) => (state ? state.editingMyAccount : null),
);

export const getSigningUp = createSelector(
  getAuthState,
  (state: AuthState) => (state?.signingUp),
);
