import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AnyOf } from '@models/anyOf.model';
import {
  User, CustomizationsSettings, LogoData,
} from '@models/company.model';
import { Response, List, Filters } from '@models/response.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyService {
  private readonly API_URL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public fetchUsers(filters: Filters): Observable<List<User>> {
    return this.http.get<Response<List<User>>>(
      `${this.API_URL}/users`,
      { params: filters as AnyOf<Filters> },
    ).pipe(
      map((resp) => resp.data),
    );
  }

  public fetchUserDetails(id: number): Observable<User> {
    return this.http.get<Response<User>>(`${this.API_URL}/users/${id}`).pipe(
      map((resp) => resp.data),
    );
  }

  public deactivateUser(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_URL}/users/change-status`, { id, status: 'inactive' });
  }

  public activateUser(id: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_URL}/users/change-status`, { id, status: 'active' });
  }

  public resendActivationLink(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.API_URL}/users/resend-activation-link/${email}`);
  }

  public deleteAccount(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.API_URL}/users/${id}`);
  }

  public isEmailTaken(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_URL}/users/check`, { email });
  }

  public addUser(userForm: User): Observable<boolean> {
    return this.http.post<boolean>(`${this.API_URL}/users`, { ...userForm });
  }

  public editUser(id: number, userForm: User): Observable<boolean> {
    return this.http.patch<boolean>(`${this.API_URL}/users/update-user-data/${id}`, { ...userForm });
  }

  public deleteLearnerAccount(): Observable<boolean> {
    return this.http.delete<boolean>(`${this.API_URL}/users/learners/delete`);
  }

  public updatePrimaryColor(primaryColor: string): Observable<boolean> {
    return this.http.patch<boolean>(
      `${this.API_URL}/organizations/settings/customizations/primary-color`, { primaryColor },
    );
  }

  public updateLogo(logo: string, logoFileName: string): Observable<LogoData> {
    return this.http.patch<Response<CustomizationsSettings>>(
      `${this.API_URL}/organizations/settings/customizations/logo`,
      { logo, logoFileName },
    ).pipe(
      map((res) => res.data),
    );
  }

  public deleteLogo(): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.API_URL}/organizations/settings/customizations/logo`,
    );
  }

  public fetchCustomizationsSettings(): Observable<CustomizationsSettings> {
    return this.http.get<Response<CustomizationsSettings>>(
      `${this.API_URL}/organizations/settings/customizations`,
    ).pipe(
      map((resp) => resp.data),
    );
  }
}
