<img
    *ngIf="severity === 'Info'"
    src="assets/img/proctoring/severity_info.svg"
    class="severity-icon"
>
<img
    *ngIf="severity === 'Critical'"
    src="assets/img/proctoring/severity_critical.svg"
    class="severity-icon"
>
<img
    *ngIf="severity === 'High'"
    src="assets/img/proctoring/severity_high.svg"
    class="severity-icon"
>
<img
    *ngIf="severity === 'Medium'"
    src="assets/img/proctoring/severity_medium.svg"
    class="severity-icon"
>
<img
    *ngIf="severity === 'Low'"
    src="assets/img/proctoring/severity_low.svg"
    class="severity-icon"
>
