import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

interface Coords {
  x: string;
  y: string;
}

@Component({
  selector: 'ros-tooltip-content',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContentComponent implements AfterViewInit {
  @ViewChild('tooltip', { read: ElementRef }) private tooltipRef!: ElementRef<HTMLDivElement>;

  @Input() public tooltipTitle!: string;
  @Input() public tooltipSubtitle?: string;
  @Input() public parentRef!: ElementRef<HTMLElement>;

  public tooltipCoords: Coords = {
    x: '0px',
    y: '0px',
  };

  public triangleCords: Coords = {
    x: '0px',
    y: '0px',
  };

  private readonly minimumLeftOffset = 48;
  private readonly appOffset = 30; // Same as $appTopPadding variable

  constructor(private changeDetector: ChangeDetectorRef) { }

  public ngAfterViewInit(): void {
    const windowWidth = window.innerWidth;

    const parentWidth = this.parentRef.nativeElement.clientWidth;
    const parentHeight = this.parentRef.nativeElement.clientWidth;

    const parentLeft = this.parentRef.nativeElement.offsetLeft;
    const parentTop = this.parentRef.nativeElement.offsetTop;

    const tooltipWidth = this.tooltipRef.nativeElement.clientWidth;
    const tooltipHeight = this.tooltipRef.nativeElement.clientHeight;

    const xPos = parentLeft;
    const yPos = parentTop - parentHeight;

    const hasSubtitle = !!this.tooltipSubtitle?.length;
    const additionalOffsetY = hasSubtitle ? 7 : 0;

    // Tooltip too close to the left edge of the screen
    if (xPos < this.minimumLeftOffset) {
      this.tooltipCoords.x = `${xPos}px`;
      this.tooltipCoords.y = `${yPos - (tooltipHeight / 2) - additionalOffsetY + this.appOffset}px`;

      this.triangleCords.x = `${parentWidth / 2}px`;
      this.triangleCords.y = `${tooltipHeight}px`;

      this.changeDetector.detectChanges();

      return;
    }

    // Tooltip to close to the right edge of the screen
    if ((xPos + tooltipWidth) > (windowWidth - this.minimumLeftOffset)) {
      this.tooltipCoords.x = `${xPos - tooltipWidth + parentWidth}px`;
      this.tooltipCoords.y = `${yPos - (tooltipHeight / 2) - additionalOffsetY + this.appOffset}px`;

      this.triangleCords.x = `${tooltipWidth - parentWidth / 2}px`;
      this.triangleCords.y = `${tooltipHeight}px`;

      this.changeDetector.detectChanges();

      return;
    }

    this.tooltipCoords.x = `${xPos - (tooltipWidth / 2) + (parentWidth / 2)}px`;
    this.tooltipCoords.y = `${yPos - (tooltipHeight / 2) - additionalOffsetY + this.appOffset}px`;

    this.triangleCords.x = `${tooltipWidth / 2}px`;
    this.triangleCords.y = `${tooltipHeight}px`;

    this.changeDetector.detectChanges();
  }
}
