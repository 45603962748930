import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
@Component({
  selector: 'ros-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() public variant: 'Primary' | 'Secondary' | 'Ternary' | 'Outline' = 'Primary';
  @Input() public type: 'Info' | 'Warning' | 'Error' | 'Neutral' = 'Neutral';
  @Input() public icon: string = 'None';
  @Input() public iconNoSeparator: boolean = false;
  @Input() public width?: '' | 'Short' | 'MediumShort' | 'Wide' = '';
  @Input() public iconPosition?: 'Left' | 'Right' | 'Center' = 'Left';
  @Input() public title!: string;
  @Input() public subtitle?: string;
  @Input() public notificationsCount?: string;
  @Input() public isDisabled?: boolean = false;
  @Input() public size?: 'Regular' | 'Large' | 'Large-wide' = 'Regular';
  @Input() public ariaLabel? = '';
  @Input() public isLoading? = false;
  @Input() public extendedWidth? = false;

  @Output() public onClick = new EventEmitter<Event>();

  public handleClick(ev: Event): void {
    if (!this.isDisabled) {
      this.onClick.emit(ev);
    }
  }
}
