import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AnyOf } from '@models/anyOf.model';
import { Response, List, Filters } from '@models/response.model';
import { environment } from '@environments/environment';
import {
  MyAssessment,
  ProductDetails,
  Products,
} from '@models/learners.model';
import { Country } from '@models/misc.model';
import { Observable } from 'rxjs';

@Injectable()
export class LearnersService {
  private readonly API_URL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public fetchMyAssessments(filters): Observable<List<MyAssessment>> {
    return this.http.get<Response<List<MyAssessment>>>(
      `${this.API_URL}/assignmentAssessments`,
      { params: filters as AnyOf<Filters> },
    ).pipe(
      map((resp) => resp.data),
    );
  }

  public fetchCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(
      `${this.API_URL}/users/constants/countries`,
    );
  }

  public fetchProducts(categoryId: number, subcategoryId?: number): Observable<Products[]> {
    return this.http.get<Response<Products[]>>(
      subcategoryId
        ? `${this.API_URL}/products/learners/list?categoryId=${categoryId}&subcategoryId=${subcategoryId}`
        : `${this.API_URL}/products/learners/list?categoryId=${categoryId}`,
    ).pipe(
      map((res) => res.data),
    );
  }

  public fetchProductDetails(reference: string): Observable<ProductDetails> {
    return this.http.get<Response<ProductDetails>>(
      `${this.API_URL}/products/${reference}`,
    ).pipe(
      map((resp) => resp.data),
    );
  }

  public buyProduct(reference: string): Observable<boolean> {
    return this.http.post<Response<boolean>>(
      `${this.API_URL}/assignmentAssessments`,
      {
        productReference: reference,
      },
    ).pipe(
      map((resp) => resp.data),
    );
  }
}
