import { AssessmentStatus } from './assessment.model';

export interface MyAssessment {
  id: number;
  name: string;
  result: MyAssessmentResult;
  status: MyAssessmentStatus;
  certificateLogo: string;
  certificateTemplateId: number;
  certificateTitle: string;
  completedAt: Date;
  description: string;
  dueAt: Date;
  exams: MyAssessmentExam[];
  reference?: string;
}

export type MyAssessmentResult = 'Passed' | 'Failed';

export enum MyAssessmentStatus {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  FINISHED = 'Finished',
}

export enum MyExamProctoringResult {
  Approved = 'Approved',
  Rejected = 'Rejected',
  WatingForProctoring = 'Waiting for proctoring',
}

export interface MyAssessmentExam {
  id: number;
  finishedAt: Date;
  maxScore: number;
  name: string;
  passingScore: number;
  price: number;
  progressStatus: MyExamProgress;
  isRetakeAvailable: boolean;
  purchasedAt: Date;
  retakePrice: number;
  status: AssessmentStatus;
  timeInSeconds: number;
  disabled: boolean;
  reference?: string;
  score?: number;
  proctoringResult: MyExamProctoringResult;
  providerSessionId?: string;
}

export type MyExamProgress = 'Not purchased' | 'Not started' | 'In progress' | 'Waiting results' | 'Failed' | 'Passed';

export interface Category {
  id: number;
  name: string;
  subcategories: {
    id: number;
    name: string;
  }[];
}

export interface Products {
  id: number;
  name: string;
  reference: string;
  description: string;
  certificateLogo: string;
  exams: {
    name: string;
    price: string;
  }[];
}

export interface ProductDetailsExam {
  id: number;
  name: string;
  price: number;
  retakePrice: number;
}

export interface ProductDetails {
  reference: string;
  id: number;
  name: string;
  longDescription: string;
  shortDescription: string;
  certificateLogo: string;
  exams: ProductDetailsExam[];
  sections: {
    name: string;
    content: string;
  }[];
}

export interface CartProductExams {
  [productId: number]: number[];
}

export interface Cart {
  products: CartProductExams;
}
