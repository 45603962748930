import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class RedirectService {
  constructor(
    private router: Router,
    private location: Location,
  ) { }

  public redirectByUrl(userPath: string, adminPath: string, extras?: NavigationExtras): void {
    const isAdminPath = this.location.path().startsWith('/admin');
    const pathToUse = isAdminPath ? adminPath : userPath;

    this.router.navigate([pathToUse], extras);
  }

  public redirectByRole(roles: string[]): void {
    if (roles.includes('learner') && roles.length === 1) {
      this.router.navigate(['my-assessments']);
    } else if ((
      this.location.path().startsWith('/set-password')
      || this.location.path().startsWith('/reset-password')
    ) && (roles.length > 1 || !roles.includes('learner'))
    ) {
      this.router.navigate(['admin/my-account']);
    } else {
      this.redirectByUrl('my-account', 'admin/my-account');
    }
  }

  public redirectBack(): void {
    this.location.back();
  }
}
