import { environment } from '@environments/environment';
import { UserData } from '@models/auth.model';
import { ScriptInjectorService } from '@modules/shared/services/scripts/script-injector.service';
import { ScriptType } from '@modules/shared/services/scripts/scriptType.enum';

export abstract class AnalyticsService {
  constructor(
    private readonly scriptInjector: ScriptInjectorService,
    private readonly scriptType: ScriptType,
  ) { }

  abstract identifyUser(data: UserData): void;

  public initialize(): void {
    const canBeInitialized = !this.scriptInjector.isScriptLoaded(this.scriptType)
      && environment.areAnalyticsEnabled;

    if (!canBeInitialized) {
      return;
    }

    this.loadScript();
  }

  private loadScript(): void {
    this.scriptInjector.loadScript(this.scriptType);
  }
}
