import {
  ElementRef,
  Injectable,
} from '@angular/core';

export interface TooltipComponent {
  id: number;
  ref: ElementRef<HTMLElement>;
  title: string;
  subtitle?: string;
}

@Injectable({ providedIn: 'root' })
export class TooltipService {
  public components: TooltipComponent[] = [];

  public addComponent(componentData: TooltipComponent): void {
    this.components.push(componentData);
  }

  public getComponents(): TooltipComponent[] {
    return this.components;
  }

  public getComponentById(id: number): TooltipComponent | undefined {

    return this.components.find((c) => c.id === id);
  }

  public deleteComponentById(id: number): void {
    const match = this.getComponentById(id);

    if (!match) {
      return;
    }

    this.components = this.components.filter((c) => c.id !== match.id);
  }
}
