/* eslint-disable no-underscore-dangle */
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ImageToEnlargeData } from '../enlarged-image/enlarged-image.component';

@Component({
  selector: 'app-id-check',
  templateUrl: './id-check.component.html',
  styleUrls: ['./id-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdCheckComponent {
  @Input() public firstName: string;
  @Input() public lastName: string;

  private _learnerPhotoUrl: string;
  @Input() public set learnerPhotoUrl(url: string | undefined) {
    this._learnerPhotoUrl = url || this.learnerPhotoPlaceholder;
  }

  public get learnerPhotoUrl(): string {
    return this._learnerPhotoUrl;
  }

  private _learnerIDPhotoUrl: string;
  @Input() public set learnerIDPhotoUrl(url: string | undefined) {
    this._learnerIDPhotoUrl = url || this.learnerIDPhotoPlaceholder;
  }

  public get learnerIDPhotoUrl(): string {
    return this._learnerIDPhotoUrl;
  }

  @Output() public onImageClick = new EventEmitter<ImageToEnlargeData>();

  public readonly learnerPhotoAlt = 'Learner photo';
  public readonly learnerIdPhotoAlt = 'Learner ID photo';

  private readonly learnerPhotoPlaceholder = '/assets/img/proctoring/learner-photo-placeholder.jpg'
  private readonly learnerIDPhotoPlaceholder = '/assets/img/proctoring/learner-id-placeholder.jpg';

  public onClick(url: string, alt: string): void {
    this.onImageClick.emit({ url, alt });
  }
}
