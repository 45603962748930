import { createAction, props } from '@ngrx/store';
import * as AuthModel from '@models/auth.model';

const PREFIX = '[AUTH]';

export const login = createAction(
  `${PREFIX} LOGIN`,
  props<{ loginForm: AuthModel.LoginForm, redirectPath?: string }>(),
);

export const loginSuccess = createAction(
  `${PREFIX} LOGIN_SUCCESS`,
  props<{ loginResponse: AuthModel.LoginResponse }>(),
);

export const loginFail = createAction(
  `${PREFIX} LOGIN_FAIL`,
);

export const initLogin = createAction(
  `${PREFIX} INIT_LOGIN`,
);

export const initLoginSuccess = createAction(
  `${PREFIX} INIT_LOGIN_SUCCESS`,
  props<{ loginResponse: AuthModel.LoginResponse }>(),
);

export const initLoginFail = createAction(
  `${PREFIX} INIT_LOGIN_FAIL`,
);

export const logout = createAction(
  `${PREFIX} LOGOUT`,
);

export const logoutSuccess = createAction(
  `${PREFIX} LOGOUT_SUCCESS`,
);

export const logoutFail = createAction(
  `${PREFIX} LOGOUT_FAIL`,
);

export const expiredTokenLogout = createAction(
  `${PREFIX} EXPIRED_TOKEN_LOGOUT`,
);

export const resetPassword = createAction(
  `${PREFIX} RESET_PASSWORD`,
  props<{ token: string, newPassword: string }>(),
);

export const setPassword = createAction(
  `${PREFIX} SET_PASSWORD`,
  props<{ token: string, newPassword: string }>(),
);

export const setPasswordSuccess = createAction(
  `${PREFIX} SET_PASSWORD_SUCCESS`,
  props<{ loginResponse: AuthModel.LoginResponse }>(),
);

export const setPasswordFail = createAction(
  `${PREFIX} SET_PASSWORD_FAIL`,
);

export const changePassword = createAction(
  `${PREFIX} CHANGE_PASSWORD`,
  props<{ currentPassword: string, password: string }>(),
);

export const changePasswordSuccess = createAction(
  `${PREFIX} CHANGE_PASSWORD_SUCCESS`,
);

export const changePasswordFail = createAction(
  `${PREFIX} CHANGE_PASSWORD_FAIL`,
);

export const changePasswordReset = createAction(
  `${PREFIX} CHANGE_PASSWORD_RESET`,
);

export const forgotPassword = createAction(
  `${PREFIX} SEND_RECOVERY_EMAIL`,
  props<{ email: string }>(),
);

export const forgotPasswordSuccess = createAction(
  `${PREFIX} SEND_RECOVERY_EMAIL_SUCCESS`,
);

export const forgotPasswordFail = createAction(
  `${PREFIX} SEND_RECOVERY_EMAIL_FAIL`,
);

export const fetchUserTokenData = createAction(
  `${PREFIX} FETCH_USER_TOKEN_DATA`,
  props<{ token: string, tokenType: string }>(),
);

export const fetchUserTokenDataSuccess = createAction(
  `${PREFIX} FETCH_USER_TOKEN_DATA_SUCCESS`,
  props<{ userTokenData: AuthModel.UserTokenData }>(),
);

export const fetchUserTokenDataFail = createAction(
  `${PREFIX} FETCH_USER_TOKEN_DATA_FAIL`,
);

export const editMyAccount = createAction(
  `${PREFIX} EDIT_MY_ACCOUNT`,
  props<{ userData: AuthModel.UserData }>(),
);
export const editMyAccountSuccess = createAction(
  `${PREFIX} EDIT_MY_ACCOUNT_SUCCESS`,
  props<{ userData: AuthModel.UserData }>(),
);
export const editMyAccountFail = createAction(
  `${PREFIX} EDIT_MY_ACCOUNT_FAIL`,
);

export const signUp = createAction(
  `${PREFIX} SIGN_UP`,
  props<{ form: AuthModel.SignUpForm }>(),
);
export const signUpSuccess = createAction(
  `${PREFIX} SIGN_UP_SUCCESS`,
);
export const signUpFail = createAction(
  `${PREFIX} SIGN_UP_FAIL`,
);
