<div class="time-data">
  <div class="time-data__container">
    <div class="time-data__examinee">
      <ros-svg-icon class="examinee-icon" icon="UserProfile"></ros-svg-icon>
      <span class="examinee-title">
        {{examineeName}}
      </span>
    </div>
    <div class="time-data__dates">
      <div class="time-data__date">
        <img
        src="assets/img/proctoring/clock_started.svg"
        class="icon"
      >
      <span class="divider"></span>
      <div class="values-wraper">
        <span class="value">
          {{ startedAt | date:'hh:mm aaa'}}
        </span>
        <span class="title">Started at</span>
      </div>
      </div>

      <div class="time-data__date">
        <img
        src="assets/img/proctoring/clock_finished.svg"
        class="icon"
        >
        <span class="divider"></span>
        <div class="values-wraper">
          <span
            *ngIf="finishedAt"
            class="value">
            {{ finishedAt | date:'hh:mm aaa' }}
          </span>
          <span class="title">Finished at</span>
        </div>
      </div>

      <div class="time-data__date">
        <img
          src="assets/img/proctoring/clock_duration.svg"
          class="icon">
        <span class="divider"></span>
        <div class="values-wraper">
          <span class="value">
            {{ duration | timeFormatter }}
          </span>
          <span class="title">Duration</span>
        </div>
      </div>
    </div>
  </div>

  <app-session-status [status]="status"> {{mappedStatus}}</app-session-status>
</div>