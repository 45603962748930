import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as AuthModel from '@models/auth.model';
import { authActions } from '@store/actions';
import { AuthState } from '@store/reducers/auth.reducer';
import { AuthService } from '@services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private localStorageUsed = false;
  private apiUrl = '';

  constructor(
    private authService: AuthService,
    private store: Store<AuthState>,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let tokens: AuthModel.Tokens = this.authService.getTokensFromLocalStorage();

    if (tokens) {
      this.localStorageUsed = true;
    } else {
      tokens = this.authService.getTokenFromSessionStorage();
      this.localStorageUsed = false;
    }

    let requestHeader = request.headers
      .set(
        'Content-Type',
        request.headers.get('Content-Type') || 'application/json',
      );

    if (tokens) {
      requestHeader = requestHeader
        .set(
          'Authorization',
          request.headers.get('Authorization') || `Bearer ${tokens.token}`,
        );
    }

    // Request to AWS S3 shouldn't have Authorization and Content-Type headers.
    if (request.url.includes('amazonaws.com')) {
      requestHeader = requestHeader.delete('Authorization');
      requestHeader = requestHeader.delete('Content-Type');
    }

    const interceptedRequest = request.clone({
      headers: requestHeader,
      url: request.url.startsWith('http')
        ? request.url
        : `${this.apiUrl}/${request.url}`,
    });

    return next.handle(interceptedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.status === 401) {
          if (tokens?.refreshToken) {
            return this.handleUnauthorizedReq(request, next, tokens.refreshToken);
          }
          this.store.dispatch(authActions.expiredTokenLogout());
        }
        return throwError(error);
      }),
    );
  }

  private handleUnauthorizedReq(
    request: HttpRequest<any>,
    next: HttpHandler,
    currentRefreshToken: string,
  ): Observable<HttpEvent<any>> {
    return this.authService.refresh(currentRefreshToken).pipe(
      switchMap(({ token, refreshToken }: AuthModel.Tokens) => {
        if (this.localStorageUsed) {
          this.authService.setTokensInLocalStorage(token, refreshToken);
        } else {
          this.authService.setTokensInSessionStorage(token, refreshToken);
        }

        const req: HttpRequest<any> = request.clone({
          setHeaders: {
            'Content-Type': request.headers.get('Content-Type') || 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        return next.handle(req);
      }),
      catchError((error: HttpErrorResponse) => this.handleWrongRefreshToken(error)),
    );
  }

  private handleWrongRefreshToken(
    error: HttpErrorResponse = null,
  ): Observable<any> {
    this.store.dispatch(authActions.expiredTokenLogout());
    return throwError(error);
  }
}
