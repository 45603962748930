<div
  class="bullet-item"
  [ngClass]="{
    'bullet-item--highlighted': bulletItem.status === bulletItemStatus.Current,
    'bullet-item--completed': bulletItem.status === bulletItemStatus.Completed
  }"
>
  <span class="bullet-item_bullet bullet-item_bullet--highlighted">
    <svg class="bullet-item_bullet-icon">
      <use [attr.xlink:href]="bulletItem.svgIconIdPath"></use>
    </svg>
  </span>
  <div class="bullet-item_text-container">
    <span class="bullet-item_title">{{ bulletItem.title }}</span>
    <span class="bullet-item_description">{{ bulletItem.description }}</span>
  </div>
</div>
