import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'lib-rosalyn-components',
  template: `
    <p class="red">
      rosalyn-components works!
    </p>
  `,
  styleUrls: ['./test.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RosalynComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
