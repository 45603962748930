<button
  [attr.aria-label]="ariaLabel"
  [disabled]="isDisabled"
  class="button"
  [ngClass]="{
    'button--large': size === 'Large',
    'button--wide': width === 'Wide',
    'button--medium-short': width === 'MediumShort',
    'button--short': width === 'Short',
    'button--primary': variant === 'Primary',
    'button--secondary': variant === 'Secondary',
    'button--outline': variant === 'Outline',
    'button--ternary': variant === 'Ternary',
    'button--no-icon': icon === 'None',
    'button--icon': icon !== 'None',
    'button--icon-left': icon !== 'None' && iconPosition === 'Left',
    'button--icon-right': icon !== 'None' && iconPosition === 'Right',
    'button--icon-center': icon !== 'None' && iconPosition === 'Center',
    'button--icon-no-separator': iconNoSeparator,
    'button--with-subtitle': subtitle && !!subtitle.length,
    'button--info': type === 'Info',
    'button--error': type === 'Error',
    'button--warning': type === 'Warning',
    'button--neutral': type === 'Neutral',
    'button--disabled': isDisabled,
    'button--extendedWidth': extendedWidth
  }"
  (keydown.enter)="handleClick($event)"
  (click)="handleClick($event)"
>
  <div class="button_wrapper">
    <ng-container *ngIf="isLoading; then loaderTemplate else basicTemplate"></ng-container>
  </div>
</button>

<ng-template #basicTemplate>
  <ng-container *ngIf="iconPosition === 'Center'; then centerIconTemplate else standardTemplate"></ng-container>
</ng-template>

<ng-template #standardTemplate>
  <div class="button_icon--left" *ngIf="icon !== 'None' && iconPosition === 'Left'">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  </div>
  <div
    class="button_title-container"
    [ngClass]="{
      'button_title-container--large': size === 'Large',
      'button_title-container--icon-right': icon !== 'None' && iconPosition === 'Right'
    }"
  >
    <div
    class="button_title"
    [ngClass]="{
      'button_title--large': size === 'Large'
    }">
      {{ title }}
    </div>
    <div class="button_subtitle" *ngIf="!!subtitle?.length">{{ subtitle }}</div>
  </div>

  <div class="button_icon--right" *ngIf="icon !== 'None' && iconPosition === 'Right'">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #loaderTemplate>
  <div class="spinner-wrapper">
    <div class="spinner" [ngClass]="{
    'spinner--info': type === 'Info' && variant === 'Outline',
    'spinner--error': type === 'Error' && variant === 'Outline',
    'spinner--warning': type === 'Warning' && variant === 'Outline',
    'spinner--neutral': type === 'Neutral' && variant === 'Outline',
    'spinner--white': variant !== 'Outline'
  }"></div>
  </div>
</ng-template>

<ng-template #centerIconTemplate>
  <div class="button_icon--center" *ngIf="icon !== 'None' && iconPosition === 'Center'">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #iconTemplate>
  <ros-svg-icon
    [icon]="icon"
    [notificationsCount]="notificationsCount"
    [type]="type"
    [contrast]="variant === 'Primary'"
    class="button_icon"
    [ngClass]="{
      'button_icon--large': size === 'Large'
    }"
  ></ros-svg-icon>
</ng-template>
