/* eslint-disable no-underscore-dangle */

import {
  Directive, ElementRef, forwardRef, HostListener, Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[appMatInputPercentage]',
  providers: [
    // eslint-disable-next-line no-use-before-define
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputPercentageDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputPercentageDirective),
      multi: true,
    },
  ],
})
export class MatInputPercentageDirective implements ControlValueAccessor {
  constructor(private element: ElementRef<HTMLInputElement>) {
  }

  private _value: string | null;

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  @HostListener('input', ['$event.target.value'])
  input(value: string) {
    this._value = value.replace(/[^\d.-]/g, '');

    // notify Angular Validators
    this._onChange(+this._value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
    // set the control as touched
    // so that the default ErrorStateMatcher works
    this._onTouched();
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  // eslint-disable-next-line no-unused-vars
  _onChange(value: any): void { }

  _onTouched(): void { }

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }


  private formatValue(value: string | null) {
    this.element.nativeElement.value = `${value}%`;
  }

  private unFormatValue() {
    const { value } = this.element.nativeElement;

    this._value = value.replace(/[^\d.-]/g, '');

    this.element.nativeElement.value = this._value;
  }
}
