import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

interface TooltipSettings {
  title: string;
  subtitle?: string;
}

@Component({
  selector: 'ros-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() public type: 'Info' | 'Warning' | 'Error' | 'Neutral' = 'Neutral';
  @Input() public icon: string = 'None';

  @Input() public tooltipSettings?: TooltipSettings;
  @Input() public ariaLabel?: string;

  @Output()
  public onClick: EventEmitter<void> = new EventEmitter();
}
