import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasicDialogComponent } from '@modules/shared/components/basic-dialog/basic-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class BasicDialogService {
  constructor(private dialog: MatDialog) { }

  public open(dialogText: string, buttonText: string, width: number): Observable<boolean> {
    return this.dialog.open(BasicDialogComponent, {
      width: `${width}px`,
      data: { dialogText, buttonText },
      panelClass: 'basic-dialog',
      disableClose: true,
    }).afterClosed();
  }
}
