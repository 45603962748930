import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthState } from '@store/reducers/auth.reducer';
import * as selectors from '@store/selectors/auth.selectors';
import { RedirectService } from '@services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private loggedIn$: Observable<boolean> = this.store.select(selectors.getLoggedIn);
  private loggingIn$: Observable<boolean> = this.store.select(selectors.getLoggingIn);

  constructor(
    private store: Store<AuthState>,
    private redirectService: RedirectService,
  ) { }

  public canActivate(): Observable<boolean> {
    return this.loggingIn$.pipe(
      filter((loggingIn) => !loggingIn),
      switchMap(() => (this.loggedIn$)),
      map((loggedIn) => {
        if (!loggedIn) {
          const targetPath = `${window.location.pathname}${window.location.search}`;

          this.redirectService.redirectByUrl(
            'login',
            'admin',
            { queryParams: { redirectPath: targetPath } },
          );
          return false;
        }

        return true;
      }),
    );
  }
}
