
  <app-severity-icon [severityValue]="severity"></app-severity-icon>
  <span [ngClass]="{
    'severity': true,
    'severity--info': severity === 'Info',
    'severity--critical': severity === 'Critical',
    'severity--high': severity === 'High',
    'severity--medium': severity === 'Medium',
    'severity--low': severity === 'Low'
  }">
  {{severity}}
</span>
