import { ActionReducerMap } from '@ngrx/store';
import { authReducer, AuthState } from './auth.reducer';
import { companyReducer, CompanyState } from './company.reducer';
import { learnersReducer, LearnersState } from './learners.reducer';

export interface AppState {
  auth: AuthState,
  company: CompanyState,
  learners: LearnersState
}

export const state: ActionReducerMap<AppState> = {
  auth: authReducer,
  company: companyReducer,
  learners: learnersReducer,
};
