import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Manager, Socket } from 'socket.io-client';
import { environment } from '@environments/environment';
import { WebsocketEvents } from '@models/websocket.model';

type ConnectionId = string;

interface ConnectionIdCacheObject {
  connectionId: ConnectionId;
  createdAt: number; // Date.now()
}

@Injectable()
export class NotificationsService {
  private readonly apiUrl = environment.apiUrl;
  private readonly wsUrl = environment.websocketUrl;
  private readonly wsPath = '/ws/notifications';
  private readonly connectionIdCacheKey = 'notificationsConnectionId';
  private readonly connectionIdCacheTTL = 1000 * 60 * 20; // 20m

  private socket: Socket<Record<WebsocketEvents, any>, {}>;

  constructor(private http: HttpClient, private router: Router) { }

  public async connect(): Promise<void> {
    const connectionId = await this.getConnectionId();
    const manager = new Manager(this.wsUrl, { path: this.wsPath });
    this.socket = manager.socket('/', { auth: { connectionId } });

    this.socket.on('connect_error', (err) => {
      if (err.message === 'Not authenticated') {
        this.socket.disconnect();
        this.router.navigateByUrl('admin');
      }
    });
  }

  public disconnect(): void {
    this.socket.disconnect();
  }

  public listen(wsEvent: WebsocketEvents, handler: Function): void {
    if (this.socket.hasListeners(wsEvent)) { return; }
    this.socket.on(wsEvent, handler);
  }

  public removeListener(wsEvent: WebsocketEvents): void {
    this.socket.removeListener(wsEvent);
  }

  private async getConnectionId(): Promise<ConnectionId> {
    let connectionId: ConnectionId;

    const cachedString: string | null = sessionStorage.getItem(this.connectionIdCacheKey);

    if (cachedString !== null) {
      try {
        const cachedObject: ConnectionIdCacheObject = JSON.parse(cachedString);
        if (Date.now() - cachedObject.createdAt < this.connectionIdCacheTTL) {
          connectionId = cachedObject.connectionId;
        }
      } catch (e) { }
    }

    if (!connectionId) {
      const { connectionId: createdConnectionId } = await this.createConnection();
      connectionId = createdConnectionId;
      const cacheObj: ConnectionIdCacheObject = { connectionId, createdAt: Date.now() };
      sessionStorage.setItem(this.connectionIdCacheKey, JSON.stringify(cacheObj));
    }
    return connectionId;
  }

  private async createConnection(): Promise<{ connectionId: ConnectionId }> {
    return this.http.post<{ connectionId: ConnectionId }>(`${this.apiUrl}/proctoring/notifications`, {}).toPromise();
  }
}
