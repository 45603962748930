import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Icon,
  iconMap,
  IconMapEntry,
} from './svg-icons';

@Component({
  selector: 'ros-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements AfterViewInit {
  @ViewChild('svgIcon', { read: TemplateRef }) private svgIconTemplate!: TemplateRef<HTMLElement>;
  @ViewChild('imgIcon', { read: TemplateRef }) private imgIconTemplate!: TemplateRef<HTMLElement>;

  @Input() public icon: string = Icon.NONE;
  @Input() public type: 'Info' | 'Warning' | 'Error' | 'Neutral' | 'Disabled' = 'Neutral';
  @Input() public isTop: boolean = false;
  @Input() public contrast: boolean = false;
  @Input() public notificationsCount?: string;
  @Input() public fixedSize: boolean = true;


  // a11y note: pass alt text, for meaningful icons that do not ends with '.svg'
  @Input() public alt?= '';

  // a11y note: meaningful icons that ends with '.svg' : pass a11yRole 'img', a11yTitle, ariaHidden=false
  @Input() public a11yRole?= 'presentation';
  @Input() public a11yTitle?: string;
  @Input() public ariaHidden?= true;

  constructor(
    private changeDetector: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  public getIconTemplateByType(icon: string): TemplateRef<HTMLElement> {
    const matchingIcon = this.getIcon(icon);

    if (matchingIcon.path.endsWith('.svg')) {
      return this.svgIconTemplate;
    }

    return this.imgIconTemplate;
  }

  public getIcon(icon: string): IconMapEntry {
    return iconMap[icon as Icon];
  }

  public getIconPath(icon: string): string {
    const matchingIcon = this.getIcon(icon);

    return `${matchingIcon.path}#${matchingIcon.svgId}`;
  }

  public getIconProp(icon: string, prop: keyof IconMapEntry): string | number | undefined {
    const matchingIcon = this.getIcon(icon);

    return matchingIcon[prop];
  }
}
