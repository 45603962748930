import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  LoginResponse,
  LoginCredentials,
  Tokens,
  UserTokenData,
  UserData,
  SignUpForm,
  ForgotPasswordResponse,
} from '@models/auth.model';
import { Response } from '@models/response.model';
import { environment } from '@environments/environment';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable()
export class AuthService {
  private readonly API_URL = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
  ) { }

  public login(credentials: LoginCredentials) {
    return this.http.post<Response<LoginResponse>>(`${this.API_URL}/auth/login`, credentials).pipe(
      map((resp) => resp.data),
    );
  }

  public refresh(refreshToken: string) {
    return this.http.post<LoginResponse>(
      `${this.API_URL}/auth/refresh`,
      {
        refreshToken,
      },
    );
  }

  public logout({ refreshToken }: Tokens) {
    return this.http.post<any>(
      `${this.API_URL}/auth/logout`,
      {
        refreshToken,
      },
    );
  }

  public setTokensInLocalStorage(token: string, refreshToken: string) {
    this.localStorageService.setLocalStorage('token', token);
    this.localStorageService.setLocalStorage('refreshToken', refreshToken);
  }

  public setTokensInSessionStorage(token: string, refreshToken: string) {
    this.sessionStorageService.setSessionStorage('token', token);
    this.sessionStorageService.setSessionStorage('refreshToken', refreshToken);
  }

  public getTokensFromLocalStorage(): Tokens {
    const token = this.localStorageService.getLocalStorage('token');
    const refreshToken = this.localStorageService.getLocalStorage('refreshToken');
    return token || refreshToken ? { token, refreshToken } : null;
  }

  public getTokenFromSessionStorage(): Tokens {
    const token = this.sessionStorageService.getSessionStorage('token');
    const refreshToken = this.sessionStorageService.getSessionStorage('refreshToken');
    return token || refreshToken ? { token, refreshToken } : null;
  }

  public cleanStorage(): void {
    this.sessionStorageService.cleanSessionStorage();
    this.localStorageService.cleanLocalStorage();
  }

  public initLogin() {
    return this.http.get<Response<LoginResponse>>(`${this.API_URL}/auth/authorize`).pipe(
      map((resp) => resp.data),
    );
  }

  public fetchUserTokenData(token: string, tokenType: string) {
    return this.http.get<Response<UserTokenData>>(`${this.API_URL}/users/token-data/${token}/${tokenType}`)
      .pipe(
        map((resp) => resp.data),
      );
  }

  public changePassword(currentPassword: string, password: string) {
    return this.http.post<Response<LoginResponse>>(
      `${this.API_URL}/users/change-password`,
      { currentPassword, password },
    ).pipe(
      map((resp) => resp.data),
    );
  }

  public setPassword(token: string, newPassword: string) {
    return this.http.post<Response<LoginResponse>>(`${this.API_URL}/users/set-password`, { token, newPassword }).pipe(
      map((resp) => resp.data),
    );
  }

  public resetPassword(token: string, newPassword: string) {
    return this.http.post<Response<LoginResponse>>(`${this.API_URL}/users/reset-password`, { token, newPassword }).pipe(
      map((resp) => resp.data),
    );
  }

  public checkPassword(id: number, password: string) {
    return this.http.post<void>(`${this.API_URL}/users/check-password`, { id, password });
  }

  public forgotPassword(email: string) {
    return this.http.post<ForgotPasswordResponse>(`${this.API_URL}/users/forgot-password`, { email }).pipe(
      map((resp) => resp.message),
    );
  }

  public editMyAccount(userData: UserData) {
    return this.http.patch<boolean>(`${this.API_URL}/users/update-user-profile`, { ...userData });
  }

  public signUp(form: SignUpForm) {
    return this.http.post<boolean>(`${this.API_URL}/users/sign-up`, { ...form });
  }
}
