import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';
import 'videojs-markers-plugin';
import { VideoMarker } from '@models/video-player.model';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';

interface CustomButtonSettings {
  title: string;
  controlBarIndex: number;
  clickHandler: () => void;
  customCSSClass?: string;
}

@Component({
  selector: 'custom-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerCustomComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) private target: ElementRef;

  @Input() public width = 496;
  @Input() public height = 300;
  @Input() public videoSource: string;
  @Input() public videoId: string;
  @Input() public markers: VideoMarker[];
  @Input() public toggleableMarkers: VideoMarker[];
  @Input() public areToggleableVisible: boolean;
  @Input() public playerTime: number;
  @Input() public videoErrorTitle: string = 'Video Unavailable';
  @Input() public videoErrorSubtitle: string;
  public player: videojs.Player;
  public currentTime = 0;
  public duration = 0;
  public videoError = false;

  private markersList: Array<VideoMarker>
  private readonly skipInterval = 15;

  constructor(
    private store: Store<AppState>,
  ) { }

  public ngOnInit(): void {
    this.player = videojs(this.target.nativeElement, {
      controls: true,
      autoplay: false,
      width: this.width,
      height: this.height,
      preload: 'auto',
      inactivityTimeout: 0,
      bigPlayButton: false,
      playbackRates: [0.5, 1, 1.5, 2, 4],
      controlBar: {
        volumePanel: {
          inline: false,
        },
        progressControl: {
          seekBar: {
            playProgressBar: {
              timeTooltip: false,
            },
          },
        },
        timeTooltip: false,
        pictureInPictureToggle: false,
        fullscreenToggle: true,
        remainingTimeDisplay: false,
        children: [
          'playToggle',
          'progressControl',
          'currentTimeDisplay',
          'timeDivider',
          'durationDisplay',
          'volumePanel',
          'playbackRateMenuButton',
          'fullscreenToggle',
        ],
      },
    });

    this.addEventsNavigationButtons();

    this.player.src(this.videoSource);
    this.player.currentTime(this.playerTime);
    if (this.markers) {
      this.setMarkers();
    }

    this.player.on('timeupdate', () => {
      this.onTimeChange(Math.round(this.player.currentTime()));
    });

    this.player.on('loadedmetadata', () => {
      this.videoError = false;
      this.onLoadMetadata(this.player.duration());
    });

    this.player.on('error', (err) => {
      this.videoError = true;
    });
  }

  private addEventsNavigationButtons(): void {
    const Button = videojs.getComponent('Button');
    const controlBar = this.player.getChild('ControlBar');
    const navigationButtonsSettings: CustomButtonSettings[] = [{
      title: 'Previous event',
      controlBarIndex: 1,
      clickHandler: ():void => {
        this.prevEvent();
      },
      customCSSClass: 'vjs-navigation-button__previous',
    }, {
      title: 'Next event',
      controlBarIndex: 2,
      clickHandler: (): void => {
        this.nextEvent();
      },
    }, {
      title: `Skip ${this.skipInterval}s backward`,
      controlBarIndex: 3,
      clickHandler: (): void => {
        this.skipBackward();
      },
      customCSSClass: 'vjs-navigation-button__skip-backward',
    }, {
      title: `Skip ${this.skipInterval}s forward`,
      controlBarIndex: 4,
      clickHandler: (): void => {
        this.skipForward();
      },
      customCSSClass: 'vjs-navigation-button__skip-forward',
    }];
    navigationButtonsSettings.forEach((setting) => {
      const button = new Button(this.player, {
        name: setting.title,
        clickHandler: setting.clickHandler,
      });
      const buttonDom = button.el();
      buttonDom.title = setting.title;
      button.addClass('vjs-navigation-button');
      if (setting.customCSSClass) {
        button.addClass(setting.customCSSClass);
      }
      controlBar.addChild(button, {}, setting.controlBarIndex);
    });
  }

  public ngOnChanges({
    videoSource,
    playerTime,
    markers,
    toggleableMarkers,
    areToggleableVisible,
    videoId: videoIdChange,
  }: SimpleChanges): void {
    if (!this.player) {
      return;
    }

    if (videoSource && videoSource.currentValue && !videoSource.firstChange) {
      const isSameVideo = !videoIdChange || (videoIdChange.currentValue === videoIdChange.previousValue);
      const isPlaying = !this.player.paused();

      this.player.pause();
      this.player.src(this.videoSource);

      if (isSameVideo) {
        this.player.currentTime(this.player.currentTime());

        if (isPlaying) {
          this.player.play();
        }
      }
    }

    if (playerTime && !playerTime.firstChange) {
      this.player.currentTime(this.playerTime);
    }

    if (markers && toggleableMarkers && !markers.firstChange && !toggleableMarkers.firstChange) {
      const isEventRejected = typeof areToggleableVisible === 'undefined';
      const isShowRejectedAlreadyUnchecked = !this.areToggleableVisible;

      const {
        previousValue: previouslyRejectedMarkers,
        currentValue: currentlyRejectedMarkers,
      }: {
        previousValue: VideoMarker[],
        currentValue: VideoMarker[],
      } = toggleableMarkers;

      if (isEventRejected) {
        if (!isShowRejectedAlreadyUnchecked) {
          return;
        }

        if (previouslyRejectedMarkers) {
          const markerToRemove = currentlyRejectedMarkers
            .filter((currMarker) => previouslyRejectedMarkers.findIndex(
              (prevMarker) => prevMarker.id === currMarker.id,
            ) === -1)[0];

          this.removeMarkers([markerToRemove]);
        } else {
          this.removeMarkers(currentlyRejectedMarkers);
        }

        return;
      }

      const hasShowRejectedBeenChecked = areToggleableVisible.currentValue;

      if (hasShowRejectedBeenChecked) {
        this.appendMarkers(currentlyRejectedMarkers);
      } else {
        this.removeMarkers(currentlyRejectedMarkers);
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  public onTimeChange(time: number) :void {
    this.currentTime = time;
  }

  public onLoadMetadata(time: number) :void {
    this.duration = time;
  }

  public skipForward(): void {
    this.player.currentTime(this.player.currentTime() + this.skipInterval);
  }

  public skipBackward(): void {
    this.player.currentTime(this.player.currentTime() - this.skipInterval);
  }

  public nextEvent(): void {
    this.player.markers.next();
  }

  public prevEvent(): void {
    const currentTime = this.player.currentTime();
    let closestPreviousMarkerTimestamp = 0;
    let nextClosestPreviousMarkerTimestamp = 0;
    for (let i = 0; i < this.markersList.length; i += 1) {
      if (this.markersList[i].time < currentTime && this.markersList[i].time > closestPreviousMarkerTimestamp) {
        closestPreviousMarkerTimestamp = this.markersList[i].time;
        if (i > 0) {
          nextClosestPreviousMarkerTimestamp = this.markersList[i - 1].time;
        }
      } else {
        break;
      }
    }

    if (currentTime - closestPreviousMarkerTimestamp > 2) {
      this.player.markers.prev();
    } else if (nextClosestPreviousMarkerTimestamp !== 0) {
      this.player.currentTime(nextClosestPreviousMarkerTimestamp);
    } else {
      this.player.markers.prev();
    }
  }

  public setCurrentTime(eventStart: number): void {
    if (this.player) {
      this.player.currentTime(eventStart);
    }
  }

  private mapMarkerClasses(markers: VideoMarker[]): VideoMarker[] {
    return markers.map((marker) => {
      switch (marker.severity) {
        case 'Critical':
          return { ...marker, class: 'marker marker--critical' };
        case 'High':
          return { ...marker, class: 'marker marker--high' };
        case 'Medium':
          return { ...marker, class: 'marker marker--medium' };
        case 'Low':
          return { ...marker, class: 'marker marker--low' };
        case 'Info':
          return { ...marker, class: 'marker marker--info' };
        default: {
          return { ...marker, class: 'marker' };
        }
      }
    });
  }

  private setMarkers(): void {
    const markers = this.mapMarkerClasses(this.markers);

    if (this.player.markers.reset) {
      this.player.markers.destroy();
    }

    this.player.markers({
      markerTip: {
        display: true,
        text(marker: VideoMarker): string {
          return marker.text;
        },
        time(marker: VideoMarker): number {
          return marker.time;
        },
      },
      markers,
    });

    this.markersList = this.player.markers.getMarkers();
  }

  private appendMarkers(markersToBeAdded: VideoMarker[]): void {
    const mappedMarkers = this.mapMarkerClasses(markersToBeAdded);

    this.player.markers.add(mappedMarkers);
  }

  private removeMarkers(markersToBeRemoved: VideoMarker[]): void {
    const allMarkers = this.player.markers.getMarkers();

    const indices = markersToBeRemoved
      .map((markerToBeRemoved: VideoMarker) => allMarkers.findIndex(
        (marker: VideoMarker) => markerToBeRemoved.index === marker.index,
      ))
      .sort();

    this.player.markers.remove(indices);
  }
}
