import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@environments/environment';
import { state } from './reducers';
import { metaReducers } from './metareducers';
import { effects } from './effects';

@NgModule({
  imports: [
    StoreModule.forRoot(state, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(effects),
  ],
})
export class AppStoreModule { }
