import {
  Component, OnInit, OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { FormBuilder, AbstractControl, Validators } from '@angular/forms';
import {
  getSettingPassword,
  getUserTokenData,
  getUserTokenDataFetching,
} from '@store/selectors';
import {
  setPassword,
  resetPassword,
  fetchUserTokenData,
} from '@store/actions/auth.actions';
import { Subscription } from 'rxjs';
import {
  filter,
  take,
  tap,
  debounceTime,
} from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { PasswordAsyncValidator } from '@auth/validators/password.async.validator';
import { TokenType } from '@models/auth.model';

import { ofType } from '@ngrx/effects';
import { authActions } from '@store/actions';
import { confirmPasswords } from '../../validators/confirm-password.validator';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  get password(): AbstractControl {
    return this.formGroup.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.formGroup.get('confirmPassword');
  }

  public settingPassword$ = this.store.select(getSettingPassword);
  public userTokenDataFetching$ = this.store.select(getUserTokenDataFetching);
  public resetMode = this.route.snapshot.data.mode || null;
  public hidePassword = true;
  public hideConfirmPassword = true;
  public passwordCheckPending = false;
  public formGroup = this.formBuilder.group({
    password: [
      null,
      [Validators.required, Validators.minLength(8), Validators.maxLength(64)],
    ],
    confirmPassword: [null, Validators.required],
  }, { validator: confirmPasswords, disabled: true });

  public showSetPasswordSuccessMessage = false;

  private userTokenData$ = this.store.select(getUserTokenData);
  private subscriptions = new Subscription();
  private token: string = this.route.snapshot.paramMap.get('token');
  private setPasswordSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private actionsSubj: ActionsSubject,
  ) {
    this.setPasswordSub = this.actionsSubj.pipe(
      ofType(authActions.setPasswordSuccess),
    ).subscribe(() => {
      this.showSetPasswordSuccessMessage = true;
    });
  }

  ngOnInit(): void {
    this.formGroup.disable();
    const tokenType = this.resetMode ? TokenType.PASSWORD_RESET : TokenType.EMAIL_VERIFICATION;
    this.store.dispatch(fetchUserTokenData({ token: this.token, tokenType }));
    this.userTokenData$.pipe(
      filter((val) => !!val),
      take(1),
    ).subscribe((userTokenData) => {
      this.password.setAsyncValidators(PasswordAsyncValidator(this.authService, userTokenData.id));
      this.formGroup.enable();
    });

    this.subscriptions.add(
      this.password.statusChanges
        .pipe(
          tap(() => { this.passwordCheckPending = false; }),
          debounceTime(500),
        )
        .subscribe((status) => {
          switch (status) {
            case 'PENDING':
              this.passwordCheckPending = true;
              this.password.markAsTouched();
              break;
            case 'VALID':
            case 'INVALID':
              this.passwordCheckPending = false;
              break;
            default:
              break;
          }
        }),
    );
  }

  public submit(): void {
    if (this.formGroup.valid) {
      if (this.resetMode) {
        this.store.dispatch(resetPassword(
          {
            token: this.token,
            newPassword: this.password.value,
          },
        ));
      } else {
        this.store.dispatch(setPassword(
          {
            token: this.token,
            newPassword: this.password.value,
          },
        ));
      }
    }
  }

  public ngOnDestroy(): void {
    this.setPasswordSub.unsubscribe();
  }
}
