<div
  *ngIf="componentVisible"
  class="enlarged-image"
>
  <div class="enlarged-image__header">
    <ros-icon-button
      icon="Cross"
      type="Normal"
      ariaLabel="Close"
      (onClick)="closeOverview()"
    ></ros-icon-button>
  </div>

  <div
    class="enlarged-image__wrapper"
    (click)="closeOverview()"
  >
    <img
      [src]="url"
      [alt]="alt"
      (click)="$event.stopPropagation()"
    >
  </div>
</div>