import {
  createReducer,
  on,
  Action,
} from '@ngrx/store';
import {
  User,
  CustomizationsSettings,
} from '@models/company.model';
import { List, Filters } from '@models/response.model';
import * as companyActions from '../actions/company.actions';

export interface CompanyState {
  userList: List<User>,
  userListFetching: boolean,
  lastUserListFilters: Filters;
  deactivatingUser: boolean;
  activatingUser: boolean;
  resendingActivationLink: boolean;
  deletingAccount: boolean;
  userAdding: boolean;
  userAdded: boolean;
  userEditing: boolean;
  userDetails: User;
  userDetailsFetching: boolean;
  logoUpdating: boolean;
  logoDeleting: boolean;
  customizationsSettings: CustomizationsSettings,
}

const initialState: CompanyState = {
  userList: null,
  lastUserListFilters: null,
  userListFetching: false,
  deactivatingUser: false,
  activatingUser: false,
  resendingActivationLink: false,
  deletingAccount: false,
  userAdding: false,
  userAdded: false,
  userEditing: false,
  userDetails: null,
  userDetailsFetching: false,
  logoUpdating: false,
  logoDeleting: false,
  customizationsSettings: null,
};

const createCompanyReducer = createReducer(
  initialState,
  on(companyActions.fetchUsers, (state, action) => ({
    ...state,
    userListFetching: true,
    lastUserListFilters: action.filters,
  })),
  on(companyActions.fetchUsersSuccess, (state, action) => ({
    ...state,
    userList: action.userList,
    userListFetching: false,
  })),
  on(companyActions.fetchUsersFail, (state) => ({
    ...state,
    userListFetching: false,
  })),

  on(companyActions.deactivateUser, (state) => ({
    ...state,
    deactivatingUser: true,
  })),
  on(companyActions.deactivateUserSuccess, (state) => ({
    ...state,
    deactivatingUser: false,
  })),
  on(companyActions.deactivateUserFail, (state) => ({
    ...state,
    deactivatingUser: false,
  })),

  on(companyActions.activateUser, (state) => ({
    ...state,
    activatingUser: true,
  })),
  on(companyActions.activateUserSuccess, (state) => ({
    ...state,
    activatingUser: false,
  })),
  on(companyActions.activateUserFail, (state) => ({
    ...state,
    activatingUser: false,
  })),

  on(companyActions.resendActivationLink, (state) => ({
    ...state,
    resendingActivationLink: true,
  })),
  on(companyActions.resendActivationLinkSuccess, (state) => ({
    ...state,
    resendingActivationLink: false,
  })),
  on(companyActions.resendActivationLinkFail, (state) => ({
    ...state,
    resendingActivationLink: false,
  })),

  on(companyActions.deleteAccount, (state) => ({
    ...state,
    deletingAccount: true,
  })),
  on(companyActions.deleteAccountSuccess, (state) => ({
    ...state,
    deletingAccount: false,
  })),
  on(companyActions.deleteAccountFail, (state) => ({
    ...state,
    deletingAccount: false,
  })),

  on(companyActions.addUser, (state) => ({
    ...state,
    userAdding: true,
  })),
  on(companyActions.addUserSuccess, (state) => ({
    ...state,
    userAdding: false,
    userAdded: true,
  })),
  on(companyActions.addUserFail, (state) => ({
    ...state,
    userAdding: false,
  })),
  on(companyActions.addUserReset, (state) => ({
    ...state,
    userAdded: false,
  })),

  on(companyActions.editUser, (state) => ({
    ...state,
    userEditing: true,
  })),
  on(companyActions.editUserSuccess, (state) => ({
    ...state,
    userEditing: false,
  })),
  on(companyActions.editUserFail, (state) => ({
    ...state,
    userEditing: false,
  })),

  on(companyActions.fetchUserDetails, (state) => ({
    ...state,
    userDetailsFetching: true,
  })),
  on(companyActions.fetchUserDetailsSuccess, (state, action) => ({
    ...state,
    userDetails: action.userDetails,
    userDetailsFetching: false,
  })),
  on(companyActions.fetchUserDetailsFail, (state) => ({
    ...state,
    userDetailsFetching: false,
  })),
  on(companyActions.fetchUserDetailsReset, (state) => ({
    ...state,
    userDetails: null,
  })),

  on(companyActions.updatePrimaryColor, (state) => ({
    ...state,
    primaryColorUpdating: true,
  })),
  on(companyActions.updatePrimaryColorSuccess, (state, action) => ({
    ...state,
    customizationsSettings: {
      ...state.customizationsSettings,
      primaryColor: action.primaryColor,
    },
    primaryColorUpdating: false,
  })),
  on(companyActions.updatePrimaryColorFail, (state) => ({
    ...state,
    primaryColorUpdating: false,
  })),

  on(companyActions.updateLogo, (state) => ({
    ...state,
    logoUpdating: true,
  })),
  on(companyActions.updateLogoSuccess, (state, action) => ({
    ...state,
    customizationsSettings: {
      ...state.customizationsSettings,
      logoUrl: action.logoUrl,
      logoFileName: action.logoFileName,
    },
    logoUpdating: false,
  })),
  on(companyActions.updateLogoFail, (state) => ({
    ...state,
    logoUpdating: false,
  })),

  on(companyActions.fetchCustomizationsSettings, (state) => ({
    ...state,
    customizationsSettingsFetching: true,
  })),
  on(companyActions.fetchCustomizationsSettingsSuccess, (state, action) => ({
    ...state,
    customizationsSettings: {
      ...action.customizationsSettings,
      logoUrl: action.customizationsSettings.logoUrl || 'assets/img/rosalyn-logo.svg',
    },
    customizationsSettingsFetching: false,
  })),
  on(companyActions.fetchCustomizationsSettingsFail, (state) => ({
    ...state,
    customizationsSettingsFetching: false,
  })),

  on(companyActions.deleteLogo, (state) => ({
    ...state,
    logoDeleting: true,
  })),
  on(companyActions.deleteLogoSuccess, (state, action) => ({
    ...state,
    customizationsSettings: {
      ...state.customizationsSettings,
      logoUrl: null,
      logoFileName: null,
    },
    logoDeleting: false,
  })),
  on(companyActions.deleteLogoFail, (state) => ({
    ...state,
    logoDeleting: false,
  })),
);

export function companyReducer(
  state: CompanyState | undefined,
  action: Action,
): CompanyState {
  return createCompanyReducer(state, action);
}
