import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsToDesc' })
export class SecondsToDescriptionPipe implements PipeTransform {
  public transform(seconds: number): string {
    let timeScale = 'minutes';
    let timeValue = Math.round(seconds / 60);

    if (seconds < 60) {
      timeValue = seconds;
      timeScale = 'seconds';
    } else if (seconds === 60) {
      timeScale = 'minute';
    }

    return seconds ? `${timeValue} ${timeScale}` : '';
  }
}
