import {
  Component,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-spinner-container',
  templateUrl: './spinner-container.component.html',
  styleUrls: ['./spinner-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SpinnerContainer {}
