import { Injectable } from '@angular/core';
import { UserData } from '@models/auth.model';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { ScriptInjectorService } from '@modules/shared/services/scripts/script-injector.service';
import { ScriptType } from '@modules/shared/services/scripts/scriptType.enum';

@Injectable({
  providedIn: 'root',
})
export class FullStoryService extends AnalyticsService {
  constructor(scriptInjector: ScriptInjectorService) {
    super(scriptInjector, ScriptType.FULLSTORY);
  }

  public identifyUser(user: UserData): void {
    const { analytics } = window as any;

    if (!analytics) {
      return;
    }

    analytics.identify(
      `user-${user.id}-${user.organization.name}-org`,
      {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
      },
    );
  }
}
