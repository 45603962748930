import { Pipe, PipeTransform } from '@angular/core';
import { RoleValues } from '@models/auth.model';
import { RoleAccessService } from '@services/role-access.service';

@Pipe({ name: 'rolesPipe' })
export class RolesPipe implements PipeTransform {
  constructor(private roleAccessService: RoleAccessService) { }

  private allRoles = this.roleAccessService.getRoles();

  transform(roles: RoleValues[]) {
    return roles?.map((roleValue) => this.allRoles.find((role) => role.value === roleValue).description);
  }
}
