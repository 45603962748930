import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  setLocalStorage(name: string, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  getLocalStorage(name: string): any {
    try {
      return JSON.parse(localStorage.getItem(name));
    } catch (error) {
      return null;
    }
  }

  cleanLocalStorage(): void {
    localStorage.clear();
  }
}
