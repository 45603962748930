import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperDialogComponent } from './image-cropper-dialog.component';

@Injectable()
export class ImageCropperDialogService {
  dialogRef: MatDialogRef<ImageCropperDialogComponent>;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  public open(event: Event, imageAspectRatio: number) {
    const files = <FileList>(<HTMLInputElement>event.target).files;
    if (files[0]) {
      const name = files[0].name.toLowerCase();
      const validSize = files[0].size < 4194304;
      const valid = name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.png');

      if (files.length === 1 && valid && validSize) {
        this.dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          data: {
            event,
            imageAspectRatio,
          },
          maxWidth: '100vw',
        });
      } else if (!valid) {
        this.snackBar.open('File has to have format \'.jpeg\', \'.jpg\' or \'.png', null, { duration: 3000 });
      } else if (!validSize) {
        this.snackBar.open('File has to be less than 4mb', null, { duration: 3000 });
      }
    }
  }

  public resultImage(): Observable<any> {
    return this.dialogRef ? this.dialogRef.afterClosed().pipe(take(1), map((res) => res)) : EMPTY;
  }
}
