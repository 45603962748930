import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { WindowService } from './window.service';

@Injectable()
export class CertificatesService {
  private readonly API_URL = environment.apiUrl;

  constructor(private http: HttpClient, private windowService: WindowService) { }

  public downloadCertificate(assignmentAssessmentId: number): Subscription {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.http.get(
      `${this.API_URL}/assignmentAssessments/certificate/${assignmentAssessmentId}`,
      { headers, responseType: 'blob' },
    ).subscribe((data) => this.downloadFile(data));
  }

  private downloadFile(data): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = this.windowService.windowRef.URL.createObjectURL(blob);
    window.open(url);
  }
}
