import { LoaderComponent } from './loader/loader.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BulletItemComponent } from './bullet-item/bullet-item.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ListContainerComponent } from './large-list/list-container/list-container.component';
import { ListItemComponent } from './large-list/list-item/list-item.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RosalynComponentsComponent } from './rosalyn-components.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SwitchComponent } from './switch/switch.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TooltipContainerComponent } from './tooltip/tooltip-container/tooltip-container.component';
import { TooltipContentComponent } from './tooltip/tooltip-content/tooltip-content.component';
import { TooltipDirective } from './tooltip/tooltip-content/tooltip-content.directive';
import {  RoundCheckbox } from './round-checkbox/round-checkbox.component';

@NgModule({
  declarations: [
    RosalynComponentsComponent,
    ButtonComponent,
    IconButtonComponent,
    SvgIconComponent,
    TooltipContainerComponent,
    TooltipContentComponent,
    TooltipDirective,
    ListItemComponent,
    ListContainerComponent,
    RadioButtonComponent,
    RoundCheckbox,
    SwitchComponent,
    CheckboxComponent,
    BulletItemComponent,
    DropdownComponent,
    TimelineComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    RosalynComponentsComponent,
    ButtonComponent,
    IconButtonComponent,
    SvgIconComponent,
    TooltipContainerComponent,
    TooltipContentComponent,
    TooltipDirective,
    ListItemComponent,
    ListContainerComponent,
    RadioButtonComponent,
    RoundCheckbox,
    SwitchComponent,
    CheckboxComponent,
    BulletItemComponent,
    DropdownComponent,
    TimelineComponent,
    LoaderComponent
  ]
})
export class RosalynComponentsModule { }
