import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-severity',
  templateUrl: './severity.component.html',
  styleUrls: ['./severity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeverityComponent {
  @Input() public set severityValue(severityValue: string) {
    this.severity = severityValue;
  }

  public severity: string;
}
