import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { EventStatus } from '@models/event.model';

@Component({
  selector: 'app-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EventStatusComponent {
  public readonly EventStatus: typeof EventStatus = EventStatus;
  public readonly EventStatusTextMap = {
    [EventStatus.REJECTED]: 'Rejected',
    [EventStatus.ACCEPTED]: 'Confirmed',
  }

  @Input() public status: EventStatus;
}
