import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

export interface ImageToEnlargeData {
  url: string;
  alt: string
}

@Component({
  selector: 'app-enlarged-image',
  templateUrl: './enlarged-image.component.html',
  styleUrls: ['./enlarged-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnlargeImageComponent {
  @Input() public set imageData(imageToEnlargeData: ImageToEnlargeData) {
    if (imageToEnlargeData.url) {
      this.url = imageToEnlargeData.url;
      this.alt = imageToEnlargeData.alt;
      this.componentVisible = true;
    }
  }

  public componentVisible: boolean = false;
  public url: string = '';
  public alt: string = '';

  public closeOverview(): void {
    this.componentVisible = false;
  }
}
