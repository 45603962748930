import {
  FormControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {
  of,
  Observable,
  timer,
} from 'rxjs';
import {
  map,
  catchError,
  switchMap,
} from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { ErrorResponse } from '@models/response.model';

export const PasswordAsyncValidator = (
  service: AuthService,
  id: number = null,
): AsyncValidatorFn => (control: FormControl): Observable<ValidationErrors | null> => timer(500)
  .pipe(
    switchMap(() => service.checkPassword(id, control.value)),
    map(() => null),
    catchError(({ error }: { error: ErrorResponse }) => of({ password: error.data.password })),
  );
