import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WindowService } from '@services/window.service';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent implements OnInit {
  private croppedImage: string;
  public imageChangedEvent: Event = this.data.event;
  public aspectRatio: number = this.data.imageAspectRatio;
  public imageLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { event, imageAspectRatio },
    private dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    private windowService: WindowService,
  ) { }

  ngOnInit(): void {
    const { data } = this;
    const files = <FileList>(<HTMLInputElement>data.event.target).files;
    const img = new Image();
    img.src = window.URL.createObjectURL(files[0]);
    img.onload = () => {
      const { width: imageWidth, height: imageHeight } = img;
      const { innerWidth: viewportWidth, innerHeight: viewportHeight } = this.windowService.windowRef;
      const dialogMinMargin = 24;
      const maxDialogWidth = viewportWidth - 2 * dialogMinMargin;
      const maxDialogHeight = viewportHeight - 2 * dialogMinMargin;
      const originalRatio = imageWidth / imageHeight;
      const dialogExtraWidth = 62; // (modal padding: 24px, image padding: 5px, image border: 2px) * 2
      const dialogExtraHeigth = 150; // (modal padding: 24px, image padding: 5px, image border: 2px) * 2, header: 42px, button: 46px,
      const needScaling = (imageWidth + dialogExtraWidth) > maxDialogWidth
        || (imageHeight + dialogExtraHeigth) > maxDialogHeight;

      const isWidthScale = (imageWidth + dialogExtraWidth + 48) / viewportWidth > (imageHeight + dialogExtraHeigth + 48) / viewportHeight;

      let finalDialogWidth;
      let finalDialogHeight;

      if (isWidthScale && needScaling) {
        finalDialogWidth = maxDialogWidth;
        const finalImageWidth = finalDialogWidth - dialogExtraWidth;
        const finalImageHeight = finalImageWidth / originalRatio;
        finalDialogHeight = finalImageHeight + dialogExtraHeigth;
      } else if (needScaling) { // height scale
        finalDialogHeight = maxDialogHeight;
        const finalImageHeight = finalDialogHeight - dialogExtraHeigth;
        const finalImageWidth = finalImageHeight * originalRatio;
        finalDialogWidth = finalImageWidth + dialogExtraWidth;
      } else { // no scaling
        finalDialogWidth = imageWidth + dialogExtraWidth;
        finalDialogHeight = imageHeight + dialogExtraHeigth;
      }

      this.dialogRef.updateSize(`${finalDialogWidth}px`, `${finalDialogHeight}px`);
      this.imageLoaded = true;
    };
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  public done(): void {
    this.close(this.croppedImage);
  }

  public close(value): void {
    this.dialogRef.close(value);
  }
}
