<div class="basic-error-dialog-container">
  <div class="icon-container">
    <div class="alert">
      <ros-svg-icon contrast='true' icon="Alert"></ros-svg-icon>
    </div>
  </div>

  <div mat-dialog-content>
    <p class="text">{{data.dialogText}}</p>
  </div>
</div>
