import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeFormatter' })
export class TimeFormatterPipe implements PipeTransform {
  public transform(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);
    const hoursPart: string = hours.toString().padStart(2, '0');
    const minutesPart: string = (minutes - hours * 60).toString().padStart(2, '0');
    const secondsPart: string = (Math.floor(seconds) - minutes * 60).toString().padStart(2, '0');

    return `${hoursPart}:${minutesPart}:${secondsPart}`;
  }
}
