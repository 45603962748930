<div class="set-password-container">
  <header class="header">
    <img
      src="assets/img/rosalyn-logo.svg"
      class="logo"
    >
    <h1 class="header__title"> Create New Password</h1>
  </header>
  <form
    *ngIf="!showSetPasswordSuccessMessage"
    class="form"
    [@.disabled]="true"
    [formGroup]="formGroup"
    (submit)="submit()"
  >
    <!--Leaving it here as it may be needed and its already styled-->
    <!-- <mat-icon class="title__hint-icon">info_outline
    </mat-icon>
    <div class="title__hint">
      <p>Password:</p>
      <ul>
        <li> - must have between 8 and 64 characters</li>
        <li> - must have a special character</li>
        <li> - must have a uppercase and lowercase letters</li>
        <li> - can't contain the user name or email address</li>
        <li *ngIf="resetMode"> - can't be the same as the old one</li>
      </ul>
    </div> -->
    <div class="password form-field-container">
      <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="form-field text-input password-form-field"
      [class.form-field--valid]="!passwordCheckPending && password.status === 'VALID'"
      hideRequiredMarker
      >
      <mat-label>
        <span>Password</span>
      </mat-label>
      <app-password-strength
      class="password-strength"
      [password]="password.value">
      </app-password-strength>
        <input
          matInput
          formControlName="password"
          [type]="hidePassword ? 'password' : 'text'"
          required
        >
        <mat-error *ngIf="password.errors?.required">
          Passwords is required
        </mat-error>
        <mat-error *ngIf="password.errors?.minlength">
          Passwords has to have 8 or more characters
        </mat-error>
        <mat-error *ngIf="password.errors?.maxlength">
          Passwords has to have 64 or less characters
        </mat-error>
        <mat-error *ngIf="password.valid">
          Passwords don't match the criteria
        </mat-error>
        <mat-error *ngIf="password.errors?.password">
          {{ password.errors?.password}}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="confirm-password form-field-container">
      <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="form-field text-input confirm-password-form-field"
      hideRequiredMarker
      >
        <mat-label>
          <span>Confirm Password</span>
        </mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          required
          [type]="hideConfirmPassword ? 'password' : 'text'"
        >
        <mat-error *ngIf="confirmPassword.errors?.required">
          Confirming passwords is required
        </mat-error>
        <mat-error *ngIf="confirmPassword.errors?.differentPasswords">
          Passwords don't match
        </mat-error>
      </mat-form-field>
    </div>

    <div class="submit">
      <ros-button
        title="Update Password"
        type="submit"
        size="Large"
        width="Wide"
        class="submit__button"
        [isDisabled]="formGroup.disabled || (settingPassword$ | async)"
      >
      </ros-button>
      <div
        class="submit__spinner"
        *ngIf="(settingPassword$ | async)"
      >
        <mat-spinner diameter="20">
        </mat-spinner>
      </div>
    </div>
  </form>
  <div class="set-password-success" *ngIf="showSetPasswordSuccessMessage">
    <span class="set-password-success__header">Success</span>
    <span class="set-password-success__message">Your password has been {{resetMode ? 'reset' : 'set'}}. </span>
    <ros-button title="Back To Login"
                routerLink="/admin"
                size="Large"
                width="Wide">
    </ros-button>
  </div>
</div>
