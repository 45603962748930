import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  SessionStatus,
} from '@models/session.model';

@Component({
  selector: 'app-session-status',
  templateUrl: './session-status.component.html',
  styleUrls: ['./session-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SessionStatusComponent {
  public readonly SessionStatus: typeof SessionStatus = SessionStatus;

  @Input() public status: SessionStatus;
}
