<div class="forgot-password-container">
  <header class="header">
    <img
    src="assets/img/rosalyn-logo.svg"
    class="logo">
    <h1 class="title">Forgot password</h1>
  </header>
  <ng-container *ngIf="!showEmailSentMessage">
    <form
      class="form"
      [formGroup]="formGroup"
      (submit)="submit()"
    >
      <div class="form-field-container mail">
        <mat-form-field
          floatLabel="always"
          appearance="fill"
          color="accent"
          class="text-input"
        >
        <mat-label>
          <span>Email</span>
        </mat-label>
          <input
            matInput
            formControlName="email"
          >
          <mat-error *ngIf="email.errors?.required">
            Email is required
          </mat-error>
          <mat-error *ngIf="email.errors?.email">
            Wrong email format
          </mat-error>
        </mat-form-field>
      </div>

      <div class="submit">
        <button
          mat-ripple
          type="submit"
          class="submit__button flat-button"
          [disabled]="sendingRecoveryEmail$ | async"
        >
          Submit
        </button>
        <div
          class="submit__spinner"
          *ngIf="(sendingRecoveryEmail$ | async)"
        >
          <mat-spinner diameter="20">
          </mat-spinner>
        </div>
      </div>
    </form>
    <footer class="footer">
      <a
      class="link"
      routerLink="/admin"
    >
      <span class="link__text">Want to Log in?</span>
      <span class="link__text">Take me back</span>
    </a>
    </footer>
  </ng-container>

  <div *ngIf="showEmailSentMessage" class="email-sent">
    <span class="email-sent__header">Email sent!</span>
    <span class="email-sent__message">If there is an account associated to this email, you will receive a password reset link.</span>
    <ros-button title="Back To Login"
                routerLink="/admin"
                size="Large"
                width="Wide">
    </ros-button>
  </div>
</div>
