import {
  Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, NgZone,
} from '@angular/core';
import { Router } from '@angular/router';

import { MyAssessment, MyAssessmentStatus, MyAssessmentExam } from '@models/learners.model';
import { BrowserDetector, ProtocolOpener } from '@modules/shared/services/protocol-opener';
import { CertificatesService } from '@services/certificates.service';

@Component({
  selector: 'app-my-assessments-card',
  templateUrl: './my-assessments-card.component.html',
  styleUrls: ['./my-assessments-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAssessmentsCardComponent {
  @Input() public assessment: MyAssessment;
  @Input() public adminMode = false;

  public MyAssessmentStatus = MyAssessmentStatus;

  public launchingExamId: number | null = null;

  public displayedColumns = ['name', 'purchasedAt', 'status', 'price', 'finishedAt', 'scores', 'action'];

  constructor(
    private router: Router,
    private zone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private certificatesService: CertificatesService,
  ) { }

  public startExam(exam: MyAssessmentExam): void {
    this.launchingExamId = exam.id;

    const browserDetector = new BrowserDetector();
    const protocolOpener = new ProtocolOpener(browserDetector.getBrowserType(), 4000);
    protocolOpener.open(
      `rosalynai://token=${exam.reference}`,
      () => {
        this.zone.run(() => this.router.navigate(['app', 'download', exam.reference]));
      },
      () => {
        this.launchingExamId = null;
        this.changeDetectorRef.detectChanges();
      },
      () => {},
    );
  }

  public downloadCertificate(assessmentId: number): void {
    this.certificatesService.downloadCertificate(assessmentId);
  }
}
