import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';
import { RoleAccessService } from '@services/role-access.service';
import { AppState } from '@store/reducers/index';
import * as selectors from '@store/selectors/auth.selectors';

@Injectable({
  providedIn: 'root',
})
// should be used along with auth.guard
export class RoleGuard implements CanActivate {
  private roles$: Observable<string[]> = this.store.select(selectors.getUserRoles);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private roleSerivce: RoleAccessService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return this.roles$.pipe(
      filter((val) => !!val),
      map((roles) => {
        if (!this.roleSerivce.hasAccess(roles, route.routeConfig.path)) {
          this.router.navigateByUrl('admin');
          return false;
        }
        return true;
      }),
    );
  }
}
