<div class="timeline">
  <div
    *ngFor="let entry of entries"
    class="timeline_entry"
    [ngClass]="{
      'timeline_entry--neutral': entry.type === 'Neutral',
      'timeline_entry--info': entry.type === 'Info',
      'timeline_entry--error': entry.type === 'Error',
      'timeline_entry--flag': entry.isFlag,
      'timeline_entry--full-circle': !entry.isFlag
    }"
  >
    <h5 class="timeline_title">{{ entry.title }}</h5>
    <span class="timeline_description">{{ entry.description }}</span>
  </div>
</div>
