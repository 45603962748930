<div
  class="svg-icon"
  [ngClass]="{
    'svg-icon--neutral': type === 'Neutral',
    'svg-icon--warning': type === 'Warning',
    'svg-icon--error': type === 'Error',
    'svg-icon--info': type === 'Info',
    'svg-icon--disabled': type === 'Disabled',
    'svg-icon--top': isTop,
    'svg-icon--contrast': contrast
  }"
>
  <ng-container *ngTemplateOutlet="getIconTemplateByType(icon)"></ng-container>
</div>

<ng-template #svgIcon>
  <span class="svg-icon_wrapper">
    <svg
      class="svg-icon_svg-contents"
      [style.width]="fixedSize ? getIconProp(icon, 'width') + 'px' : '100%'"
      [style.height]="fixedSize ? getIconProp(icon, 'height') + 'px': '100%'"
      [attr.aria-hidden]="ariaHidden"
      [attr.role]="a11yRole"
      [attr.alt]="alt"
    >
      <title *ngIf="a11yTitle; let title">{{title}}</title>
      <use [attr.xlink:href]="getIconPath(icon)" />
    </svg>
    <span
      *ngIf="!!notificationsCount"
      class="svg-icon_notification"
    >
      {{ notificationsCount }}
    </span>
  </span>
</ng-template>

<ng-template #imgIcon>
  <span class="svg-icon_wrapper">
    <img [attr.alt]="alt"  [attr.role]="a11yRole" [src]="getIconProp(icon, 'path')" />
  </span>
</ng-template>

