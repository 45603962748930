export enum Icon {
  NONE = 'None',
  ROSALYN = 'Rosalyn',
  DOTS = 'Dots',
  ALERT = 'Alert',
  COG = 'Cog',
  CHECKMARK = 'Checkmark',
  CHECKMARKXL = 'CheckmarkXL',
  CROSS = 'Cross',
  CHEVRON_UP = 'ChevronUp',
  CHEVRON_RIGHT = 'ChevronRight',
  EXTERNAL = 'External',
  CHAT_BUBBLE = 'ChatBubble',
  CALENDAR_DOTS = 'CalendarDots',
  CALENDAR_CROSS = 'CalendarCross',
  CALENDAR_CHECKMARK = 'CalendarCheckmark',
  ROSALYN_LOGOTYPE = 'RosalynLogotype',
  DOWNLOAD = 'Download',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_RIGHTXL = 'ArrowRightXL',
  APP = 'App',
  CAMERA = 'Camera',
  MICROPHONE = 'Microphone',
  PREFLIGHT = 'Preflight',
  PROCTOR_EVENT = 'ProctorEvent',
  QUIZ_COMPLETE = 'QuizComplete',
  QUIZ_COMPLETED = 'QuizCompleted',
  QUIZ_HOME = 'QuizHome',
  QUIZ_HOME_CUT = 'QuizHomeCut',
  QUIZ_UPCOMING = 'QuizUpcoming',
  SCREEN = 'Screen',
  SOMETHING_WENT_WRONG = 'SomethingWentWrong',
  WELCOME = 'Welcome',
  WELCOME_CUT = 'WelcomeCut',
  USER_PROFILE = 'UserProfile'
}

export interface IconMapEntry {
  path: string;
  width?: number;
  height?: number;
  svgId?: string;
}

type IconMap = {
  [key in Icon]: IconMapEntry;
}

export const iconMap: any = {
  None: {
    path: '',
    width: 0,
    height: 0,
    svgId: '',
  },
  App: {
    path: 'assets/icons/app.svg',
    svgId: 'app',
    width: 13,
    height: 13,
  },
  Alert: {
    path: 'assets/icons/alert.svg',
    svgId: 'alert',
    width: 2,
    height: 11,
  },
  Camera: {
    path: 'assets/icons/camera.svg',
    svgId: 'camera',
    width: 24,
    height: 20,
  },
  Microphone: {
    path: 'assets/icons/microphone.svg',
    svgId: 'microphone',
    width: 16,
    height: 26,
  },
  Preflight: {
    path: 'assets/icons/preflight.svg',
    svgId: 'preflight',
    width: 275,
    height: 269,
  },
  ProctorEvent: {
    path: 'assets/icons/proctor-event.svg',
    svgId: 'proctorEvent',
    width: 275,
    height: 218,
  },
  QuizComplete: {
    path: 'assets/icons/quiz-complete.svg',
    svgId: 'quizComplete',
    width: 275,
    height: 275,
  },
  QuizHome: {
    path: 'assets/icons/quiz-home.svg',
    svgId: 'quizHome',
    width: 275,
    height: 187,
  },
  QuizHomeCut: {
    path: 'assets/icons/quiz-home-cut.svg',
    svgId: 'quizHomeCut',
    width: 356,
    height: 265,
  },
  Screen: {
    path: 'assets/icons/screen.svg',
    svgId: 'screen',
    width: 22,
    height: 22,
  },
  SomethingWentWrong: {
    path: 'assets/icons/something-went-wrong.svg',
    svgId: 'somethingWentWrong',
    width: 276,
    height: 221,
  },
  UserProfile: {
    path: 'assets/icons/user-profile.svg',
    svgId: 'userProfile',
    width: 55,
    height: 55,
  },
  Welcome: {
    path: 'assets/icons/welcome.svg',
    svgId: 'welcome',
    width: 275,
    height: 182,
  },
  WelcomeCut: {
    path: 'assets/icons/welcome-cut.svg',
    svgId: 'welcomeCut',
    width: 349,
    height: 293,
  },
  ArrowRightXL: {
    path: 'assets/icons/arrow-right-xl.svg',
    width: 17,
    height: 13,
    svgId: 'arrowRightXL',
  },
  ArrowRight: {
    path: 'assets/icons/arrow-right.svg',
    width: 7,
    height: 6,
    svgId: 'arrowRight',
  },
  ChatBubble: {
    path: 'assets/icons/chat-bubble.svg',
    width: 16,
    height: 16,
    svgId: 'chatBubble',
  },
  CalendarDots: {
    path: 'assets/icons/calendar-dots.svg',
    width: 19,
    height: 20,
    svgId: 'calendarDots',
  },
  CalendarCheckmark: {
    path: 'assets/icons/calendar-checkmark.svg',
    width: 19,
    height: 20,
    svgId: 'calendarCheckmark',
  },
  CalendarCross: {
    path: 'assets/icons/calendar-cross.svg',
    width: 19,
    height: 20,
    svgId: 'calendarCross',
  },
  Checkmark: {
    path: 'assets/icons/checkmark.svg',
    width: 11,
    height: 9,
    svgId: 'checkmark',
  },
  CheckmarkXL: {
    path: 'assets/icons/checkmarkXL.svg',
    width: 33,
    height: 27,
    svgId: 'checkmarkXL',
  },
  ChevronUp: {
    path: 'assets/icons/chevron-up.svg',
    width: 8,
    height: 6,
    svgId: 'chevronUp',
  },
  ChevronRight: {
    path: 'assets/icons/chevron-right.svg',
    width: 5,
    height: 7,
    svgId: 'chevronRight',
  },
  Cog: {
    path: 'assets/icons/cog.svg',
    width: 13,
    height: 13,
    svgId: 'cog',
  },
  Cross: {
    path: 'assets/icons/cross.svg',
    width: 9,
    height: 9,
    svgId: 'cross',
  },
  Dots: {
    path: 'assets/icons/dots.svg',
    width: 11,
    height: 3,
    svgId: 'dots',
  },
  Download: {
    path: 'assets/icons/download.svg',
    width: 16,
    height: 16,
    svgId: 'download',
  },
  External: {
    path: 'assets/icons/external.svg',
    width: 10,
    height: 10,
    svgId: 'external',
  },
  Rosalyn: {
    path: 'assets/icons/rosalyn-logo-xs.svg',
    width: 17,
    height: 17,
    svgId: 'rosalyn',
  },
  RosalynLogotype: {
    path: 'assets/icons/rosalyn-logotype.svg',
    width: 71,
    height: 40,
    svgId: 'rosalynLogotype',
  },
};
