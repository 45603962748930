<div
class="proctoring-settings"
[ngClass]="{
  'proctoring-settings__elongated': elongatedView
}"
>
  <div class="proctoring-settings__section" *ngIf="forbiddenRules.length" >
    <div class="section-title">
      <img src="assets/icons/raised-hand.svg" class="exam-rule-emoji" >
      <p> Forbidden Actions </p>
    </div>
    <div
      *ngFor="let rule of forbiddenRules"
      class="exam-rule-div"
      >
        <img
          src="assets/icons/{{rule}}.svg"
          class="rule-svg"
        >
        <p class="rule-text">{{forbiddenProctoringRuleToText[rule]}}</p>
    </div>
  </div>
  <div class="proctoring-settings__section" *ngIf="allowedRules.length" >
    <div class="section-title">
      <img src="assets/icons/thumbs-up.svg" class="exam-rule-emoji" >
      <p> Permitted Actions </p>
    </div>

  <div class="exam-rules-div">
    <div
      *ngFor="let rule of allowedRules"
      class="exam-rule-div"
      >
        <img
          src="assets/icons/{{rule}}.svg"
          class="rule-svg"
        >
        <p class="rule-text">{{allowedProctoringRuleToText[rule]}}</p>
        </div>
      </div>
    </div>
</div>