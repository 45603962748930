<button
  tooltip
  [tooltipTitle]="tooltipSettings?.title"
  [tooltipSubtitle]="tooltipSettings?.subtitle"
  [attr.aria-label]="ariaLabel"
  (click)="onClick.emit()"
  class="icon-button"
  [ngClass]="{
    'icon-button--neutral': type === 'Neutral',
    'icon-button--info': type === 'Info',
    'icon-button--warning': type === 'Warning',
    'icon-button--error': type === 'Error'
  }"
>
  <span class="icon-button_icon-container">
    <ng-content *ngTemplateOutlet="iconTemplate"></ng-content>
  </span>
</button>

<ng-template #iconTemplate>
  <ros-svg-icon
    [icon]="icon"
    [type]="type"
  ></ros-svg-icon>
</ng-template>
