import {
  Component,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  SessionStatus,
} from '@models/session.model';

@Component({
  selector: 'app-session-time-data',
  templateUrl: './session-time-data.component.html',
  styleUrls: ['./session-time-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SessionTimeDataComponent {
  @Input() public examineeName: string;
  @Input() public startedAt: Date;
  @Input() public finishedAt: Date;
  @Input() public duration: number;
  @Input() public status: SessionStatus;
  @Input() public resultsMode: boolean = false;

  public readonly sessionStatusesEnablingProctoring = [
    SessionStatus.WAITING_FOR_PROCTORING,
    SessionStatus.WAITING_FOR_FINAL_APPROVAL,
  ];

  public get mappedStatus(): SessionStatus {
    if (this.resultsMode) {
      return this.status;
    }

    return this.sessionStatusesEnablingProctoring.includes(this.status)
      ? SessionStatus.PROCTORING_IN_PROGRESS
      : this.status;
  }
}
