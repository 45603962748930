<div class="circle"></div>
<mat-progress-spinner
  class="password-spinner"
  mode="determinate"
  diameter="27"
  strokeWidth="3"
  [class.password-spinner--valid]="strength > 3"
  [value]="strength * 2 * 10">
</mat-progress-spinner>
<div class="checkmark" *ngIf="strength === 5">
  <ros-svg-icon icon="Checkmark"></ros-svg-icon>
</div>
