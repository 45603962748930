import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LearnersState } from '@store/reducers/learners.reducer';

export const getLearnersState = createFeatureSelector<LearnersState>('learners');

export const getMyAssessments = createSelector(
  getLearnersState,
  (state: LearnersState) => state.myAssessments,
);

export const getMyAssessmentById = createSelector(
  getLearnersState,
  (state: LearnersState, id: number) => state
    .myAssessments?.find((assessment) => assessment.id === id),
);

export const getMyAssessmentsFetching = createSelector(
  getLearnersState,
  (state: LearnersState) => state.myAssessmentsFetching,
);

export const getCountries = createSelector(
  getLearnersState,
  (state: LearnersState) => state.countries,
);

export const getCategories = createSelector(
  getLearnersState,
  (state: LearnersState) => state.categories,
);

export const getProductDetails = createSelector(
  getLearnersState,
  (state: LearnersState) => state.productDetails,
);

export const getProductDetailsFetching = createSelector(
  getLearnersState,
  (state: LearnersState) => state.productDetailsFetching,
);

export const getProductBuying = createSelector(
  getLearnersState,
  (state: LearnersState) => state.productBuying,
);

export const getProductsList = createSelector(
  getLearnersState,
  (state: LearnersState) => state.products,
);

export const getCartProducts = createSelector(
  getLearnersState,
  (state: LearnersState) => state.cart.products,
);

export const getCartExamCount = createSelector(
  getLearnersState,
  (state: LearnersState) => {
    const cartExams = Object
      .values(state.cart.products)
      .reduce((accumulator, productExams) => [...accumulator, ...productExams], []);
    return cartExams?.length;
  },
);
