
<div class="login-container">
  <header class="header">
    <img
      src="assets/img/rosalyn-logo.svg"
      class="logo"
    >
    <h1 class="title">Log-in</h1>
  </header>
  <form
    class="form"
    [formGroup]="formGroup"
    [@.disabled]="true"
    (submit)="submit()"
  >
    <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="form-field text-input"
      data-id="email"
      hideRequiredMarker
    >
      <mat-label>
        <span>Email</span>
      </mat-label>
      <input
        matInput
        placeholder="you@example.com"
        formControlName="email"
        required
      >
      <mat-error *ngIf="email.errors?.required">
        Email is required
      </mat-error>
      <mat-error *ngIf="email.errors?.email">
        Wrong email format
      </mat-error>
    </mat-form-field>
    <mat-form-field
      floatLabel="always"
      appearance="fill"
      class="form-field text-input"
      data-id="password"
      hideRequiredMarker
    >
      <mat-label>
        <span>Password</span>
      </mat-label>
      <input
        matInput
        formControlName="password"
        required
        placeholder="Your password here"
        type="password"
      >
      <mat-error *ngIf="password.errors?.required">
        Password is required
      </mat-error>
    </mat-form-field>

    <div class="submit">
      <ros-button type="submit" data-id="submit" [isDisabled]="(loggingIn$ | async) || formGroup.invalid" size="Large" width="Wide" title="Log In"></ros-button>
      <div
        *ngIf="loggingIn$ | async"
        class="submit__spinner"
      >
        <mat-spinner diameter="20">
        </mat-spinner>
      </div>
    </div>
  </form>
  <footer class="footer">
    <a
    class="link"
    routerLink="/forgot-password"
  >
    <span class="link__text">Forgot your password?</span>
    <span class="link__text">Request a new one</span>
  </a>
  </footer>
</div>
