import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminModule } from '@modules/admin/admin.module';
import { DeliveryModule } from '@modules/delivery/delivery.module';
import { LearnersModule } from '@modules/learners/learners.module';
import { ResultsModule } from '@modules/results/results.module';

const routes: Routes = [
  {
    loadChildren: (): Promise<DeliveryModule> => import('./modules/delivery/delivery.module').then(
      (m) => m.DeliveryModule,
    ),
    path: 'app',
  },
  {
    loadChildren: (): Promise<AdminModule> => import('./modules/admin/admin.module').then(
      (m) => m.AdminModule,
    ),
    path: 'admin',
  },
  {
    loadChildren: (): Promise<LearnersModule> => import('./modules/learners/learners.module').then(
      (m) => m.LearnersModule,
    ),
    path: '',
  },
  {
    loadChildren: (): Promise<LearnersModule> => import('./modules/auth/auth.module').then(
      (m) => m.AuthModule,
    ),
    path: '',
  },
  {
    loadChildren: (): Promise<ResultsModule> => import('./modules/results/results.module').then(
      (m) => m.ResultsModule,
    ),
    path: 'results',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
