/* eslint-disable max-classes-per-file */
/* eslint-disable no-cond-assign */
type Callback = () => void;

export enum BrowserType {
  FIREFOX = 'FIREFOX',
  EDGE = 'EDGE',
  IE = 'IE',
  CHROME = 'CHROME',
  OPERA = 'OPERA',
  SAFARI = 'SAFARI',
  UNKNOWN = 'UNKNOWN',
}

export class BrowserDetector {
  public getBrowserType(): BrowserType {
    if (this.isFirefox()) {
      return BrowserType.FIREFOX;
    }
    if (this.isEdge()) {
      return BrowserType.EDGE;
    }
    if (this.isIE()) {
      return BrowserType.IE;
    }
    if (this.isChrome()) {
      return BrowserType.CHROME;
    }
    if (this.isOpera()) {
      return BrowserType.OPERA;
    }
    if (this.isOSX()) {
      return BrowserType.SAFARI;
    }
    return BrowserType.UNKNOWN;
  }

  public getBrowserVersion(): number {
    const ua = window.navigator.userAgent;
    let tem;
    let M = ua.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
    ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return parseFloat(tem[1]) || 0;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return parseFloat(tem[2]);
      }
    }
    M = M[2]
      ? [M[1], M[2]]
      : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return parseFloat(M[1]);
  }

  public isOSX(): boolean {
    return this.userAgentContains('Macintosh');
  }

  public isFirefox(): boolean {
    return this.userAgentContains('firefox');
  }

  public isIE(): boolean {
    const ua = this.getUserAgent().toLowerCase();

    // Test values.
    // Uncomment to check result

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko/20100101 Firefox/12.0';

    const msie = ua.indexOf('msie');
    if (msie > 0) {
      // IE 10 or older
      return true;
    }

    const trident = ua.indexOf('trident/');
    if (trident > 0) {
      // IE 11
      return true;
    }

    // other browser
    return false;
  }

  public isEdge(): boolean {
    const ua = this.getUserAgent().toLowerCase();

    // Test values.
    // Uncomment to check result

    // Edge
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.10240';

    const edge = ua.indexOf('edge');
    if (edge > 0) {
      return true;
    }

    return false;
  }

  public isChrome(): boolean {
    // IE11 returns undefined for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true for window.chrome
    // and if not iOS Chrome check
    const isChromium = (window as any).chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof (window as any).opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');
    return (
      (isChromium !== null
        && typeof isChromium !== 'undefined'
        && vendorName === 'Google Inc.'
        && isOpera === false
        && isIEedge === false)
      || isIOSChrome !== null
    );
  }

  public isOpera(): boolean {
    return this.userAgentContains(' OPR/');
  }

  private getUserAgent(): string {
    return window.navigator.userAgent;
  }

  private userAgentContains(browserName: string): boolean {
    return (
      this.getUserAgent()
        .toLowerCase()
        .indexOf(browserName.toLowerCase()) > -1
    );
  }
}

export class ProtocolOpener {
  private readonly defaultTimeout = 2000;

  private timeout: number;
  private browser: BrowserType;

  constructor(browser: BrowserType, timeout?: number) {
    this.browser = browser;
    this.timeout = timeout ?? this.defaultTimeout;
  }

  public open(uri: string, onFail: Callback, onSuccess: Callback, onUnsupported?: Callback): void {
    if (document.hasFocus()) {
      this.openUri(uri, onFail, onSuccess, onUnsupported);
    } else {
      const focusHandler = this.registerEvent(window, 'focus', () => {
        focusHandler.remove();
        this.openUri(uri, onFail, onSuccess, onUnsupported);
      });
    }
  }

  private openUri(uri: string, onFail: Callback, onSuccess: Callback, onUnsupported?: Callback): void {
    switch (this.browser) {
      case BrowserType.FIREFOX: {
        this.openUriWithHiddenFrame(uri, onFail, onSuccess);
        break;
      }
      case BrowserType.CHROME:
      case BrowserType.OPERA: {
        this.openUriWithTimeoutHack(uri, onFail, onSuccess);
        break;
      }
      case BrowserType.SAFARI: {
        this.openUriWithHiddenFrame(uri, onFail, onSuccess);
        break;
      }
      case BrowserType.EDGE:
      case BrowserType.IE: {
        this.openUriWithMsLaunchUri(uri, onFail, onSuccess);
        break;
      }
      default: {
        if (onUnsupported) {
          onUnsupported();
        }
      }
    }
  }

  private registerEvent(target: any, event: string, cb: Callback): { remove: Callback } {
    if (target.addEventListener) {
      target.addEventListener(event, cb);
      return {
        remove(): void {
          target.removeEventListener(event, cb);
        },
      };
    }
    target.attachEvent(event, cb);
    return {
      remove(): void {
        target.detachEvent(event, cb);
      },
    };
  }

  private openUriWithHiddenFrame(uri: string, onFail: Callback, onSuccess: Callback): void {
    let handler;

    const iFrame: HTMLIFrameElement = (
      document.querySelector('#hiddenIframe') ?? this.createHiddenIframe(document.body, 'about:blank')
    );

    const timeout = setTimeout(() => {
      onFail();
      handler.remove();
    }, this.timeout);

    handler = this.registerEvent(window, 'blur', () => {
      clearTimeout(timeout);
      handler.remove();
      onSuccess();
    });

    iFrame.contentWindow.location.href = uri;
  }

  private createHiddenIframe(target: HTMLElement, uri: string): HTMLIFrameElement {
    const iframe = document.createElement('iframe');
    iframe.src = uri;
    iframe.id = 'hiddenIframe';
    iframe.style.display = 'none';
    target.appendChild(iframe);
    return iframe;
  }

  private openUriWithTimeoutHack(uri: string, onFail: Callback, onSuccess: Callback): void {
    let target: any = window;
    let handler;

    while (target.parent && target !== target.parent) {
      target = target.parent;
    }

    const timeout = setTimeout(() => {
      onFail();
      handler.remove();
    }, this.timeout);

    handler = this.registerEvent(target, 'blur', () => {
      clearTimeout(timeout);
      handler.remove();
      onSuccess();
    });

    window.location.href = uri;
  }

  private openUriWithMsLaunchUri(uri: string, onFail: () => void, onSuccess: () => void): void {
    navigator.msLaunchUri(uri, onSuccess, onFail);
  }
}
