<div
  #tooltip
  class="tooltip"
  [ngStyle]="{
    top: tooltipCoords.y,
    left: tooltipCoords.x
  }"
>
  <p class="tooltip_title">{{ tooltipTitle }}</p>
  <p class="tooltip_subtitle">{{ tooltipSubtitle }}</p>
  <span
    class="tooltip_triangle"
    [ngStyle]="{
      top: triangleCords.y,
      left: triangleCords.x
    }"
  ></span>
</div>
