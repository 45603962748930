import {
  isPlatformBrowser,
  DOCUMENT,
} from '@angular/common';
import {
  Inject,
  Injectable,
  InjectionToken,
  PLATFORM_ID,
} from '@angular/core';
import { Cookie } from '@models/cookie.model';

@Injectable()
export class CookieService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  setCookies(cookie: Cookie): void {
    if (isPlatformBrowser(this.platformId)) {
      const todayTimeStamp: number = Date.now();
      const addedTimeStamp: number = todayTimeStamp + cookie.expiresIn * 1000;
      const UTCdate: Date = new Date(addedTimeStamp);

      this.document.cookie = `${cookie.name}=${cookie.value}; path=/;expires=${UTCdate}`;
    }
  }

  readCookies(): Cookie[] {
    return (isPlatformBrowser(this.platformId) && this.document.cookie.length > 0)
      ? this.document.cookie.split(';').map((cookieString: string) => {
        const cookiePart: string[] = cookieString.split('=');
        return {
          name: cookiePart[0].trim(),
          value: cookiePart[1].trim(),
        };
      }) : [];
  }

  deleteCookies(): void {
    if (isPlatformBrowser(this.platformId)) {
      const cookiesArray: string[] = this.document.cookie.split(';');
      cookiesArray.forEach((cookie: string) => {
        const cookiePart: string[] = cookie.split('=');
        this.document.cookie = `${cookiePart[0].trim()}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      });
    }
  }

  deleteCookie(name: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }

  getCookie(name: string): string {
    if (isPlatformBrowser(this.platformId)) {
      const { cookie } = this.document;
      if (cookie.includes(name)) {
        const result: RegExpMatchArray = cookie.match(
          new RegExp(`(^| )${name}=([^;]+)`),
        );
        return result ? result[2] : null;
      }
    }
    return null;
  }
}
