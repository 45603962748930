import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { AppState } from '@store/reducers';
import { CompanyService } from '@services/company.service';
import { logout } from '@store/actions/auth.actions';
import { LogoData } from '@models/company.model';
import { BasicSnackBarService } from '@shared/services/basic-snackbar.service';
import { companyActions } from '../actions';
import { getLatestUserListFilters } from '../selectors';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private snackBar: BasicSnackBarService,
    private store: Store<AppState>,
    private router: Router,
  ) { }

  public $fetchUsers = createEffect(() => this.actions$.pipe(
    ofType(companyActions.fetchUsers),
    switchMap(({ filters }) => this.companyService.fetchUsers(filters)
      .pipe(
        map((userList) => companyActions.fetchUsersSuccess({ userList })),
        catchError(() => of(companyActions.fetchUsersFail())),
      )),
  ));

  public $fetchUserDetails = createEffect(() => this.actions$.pipe(
    ofType(companyActions.fetchUserDetails),
    switchMap(({ id }) => this.companyService.fetchUserDetails(id)
      .pipe(
        map((userDetails) => companyActions.fetchUserDetailsSuccess({ userDetails })),
        catchError(() => of(companyActions.fetchUserDetailsFail())),
      )),
  ));

  public $deactivateUser = createEffect(() => this.actions$.pipe(
    ofType(companyActions.deactivateUser),
    switchMap(({ id }) => this.companyService.deactivateUser(id)
      .pipe(
        map(() => {
          this.snackBar.open('User deactivated');
          return companyActions.deactivateUserSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot deactivate user');
          return of(companyActions.deactivateUserFail());
        }),
      )),
  ));

  public $activateUser = createEffect(() => this.actions$.pipe(
    ofType(companyActions.activateUser),
    switchMap(({ id }) => this.companyService.activateUser(id)
      .pipe(
        map(() => {
          this.snackBar.open('User activated');
          return companyActions.activateUserSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot activate user');
          return of(companyActions.activateUserFail());
        }),
      )),
  ));

  public $resendActivationLink = createEffect(() => this.actions$.pipe(
    ofType(companyActions.resendActivationLink),
    switchMap(({ email }) => this.companyService.resendActivationLink(email)
      .pipe(
        map(() => {
          this.snackBar.open('Activation link resent');
          return companyActions.resendActivationLinkSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot resend activation link');
          return of(companyActions.resendActivationLinkFail());
        }),
      )),
  ));

  public $deleteAccount = createEffect(() => this.actions$.pipe(
    ofType(companyActions.deleteAccount),
    switchMap(({ id }) => this.companyService.deleteAccount(id)
      .pipe(
        map(() => {
          this.snackBar.open('Account deleted');
          return companyActions.deleteAccountSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot delete an account');
          return of(companyActions.deleteAccountFail());
        }),
      )),
  ));

  public $refreshUserList = createEffect(() => this.actions$.pipe(
    ofType(
      companyActions.deactivateUserSuccess,
      companyActions.activateUserSuccess,
      companyActions.deleteAccountSuccess,
    ),
    withLatestFrom(this.store.select(getLatestUserListFilters)),
    tap(() => this.router.navigateByUrl('admin/company-settings/users')),
    map(([, filters]) => companyActions.fetchUsers({ filters })),
  ));

  public $addUser = createEffect(() => this.actions$.pipe(
    ofType(companyActions.addUser),
    switchMap(({ userForm }) => this.companyService.addUser(userForm)
      .pipe(
        map(() => {
          this.snackBar.open('User added');
          return companyActions.addUserSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot add user');
          return of(companyActions.addUserFail());
        }),
      )),
  ));

  public $editUser = createEffect(() => this.actions$.pipe(
    ofType(companyActions.editUser),
    switchMap(({ id, userForm }) => this.companyService.editUser(id, userForm)
      .pipe(
        map(() => {
          this.snackBar.open('User edited');
          return companyActions.editUserSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot edit user');
          return of(companyActions.editUserFail());
        }),
      )),
  ));

  public $deleteLearnerAccount = createEffect(() => this.actions$.pipe(
    ofType(companyActions.deleteLearnerAccount),
    switchMap(() => this.companyService.deleteLearnerAccount()
      .pipe(
        tap(() => this.snackBar.open('Account has been deleted')),
        map(() => companyActions.deleteLearnerAccountSuccess()),
        catchError(() => {
          this.snackBar.open('Account could not be deleted');
          return of(companyActions.deleteLearnerAccountFail());
        }),
      )),
  ));

  public $updatePrimaryColor = createEffect(() => this.actions$.pipe(
    ofType(companyActions.updatePrimaryColor),
    switchMap(({ primaryColor }) => this.companyService.updatePrimaryColor(primaryColor)
      .pipe(
        map(() => {
          this.snackBar.open('Primary color updated.');
          return companyActions.updatePrimaryColorSuccess({ primaryColor });
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot update primary color');
          return of(companyActions.updateLogoFail());
        }),
      )),
  ));

  public $updateLogo = createEffect(() => this.actions$.pipe(
    ofType(companyActions.updateLogo),
    switchMap(({ logo, logoFileName }) => this.companyService.updateLogo(logo, logoFileName)
      .pipe(
        map((logoData: LogoData) => {
          this.snackBar.open('Logo updated');
          return companyActions.updateLogoSuccess(logoData);
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot update logo');
          return of(companyActions.updateLogoFail());
        }),
      )),
  ));

  public $deleteLogo = createEffect(() => this.actions$.pipe(
    ofType(companyActions.deleteLogo),
    switchMap(() => this.companyService.deleteLogo()
      .pipe(
        map(() => {
          this.snackBar.open('Logo deleted');
          return companyActions.deleteLogoSuccess();
        }),
        catchError(() => {
          this.snackBar.open('Error: cannot delete logo');
          return of(companyActions.deleteLogoFail());
        }),
      )),
  ));

  public $fetchCustomizationsSettings = createEffect(() => this.actions$.pipe(
    ofType(companyActions.fetchCustomizationsSettings),
    switchMap(() => this.companyService.fetchCustomizationsSettings()
      .pipe(
        map((customizationsSettings) => companyActions.fetchCustomizationsSettingsSuccess({ customizationsSettings })),
        catchError(() => of(companyActions.fetchCustomizationsSettingsFail())),
      )),
  ));

  public $logoutAfterDelete = createEffect(() => this.actions$.pipe(
    ofType(companyActions.deleteLearnerAccount),
    map(() => this.store.dispatch(logout())),
    catchError((error) => of(error)),
  ), { dispatch: false });
}
