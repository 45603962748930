<div class="sign-up-container">
  <h4 class="title">Sign up</h4>
  <form
    [formGroup]="formGroup"
    (submit)="submit()"
    class="form"
  >
    <div class="names">
      <div class="form-field-container names__name">
        <mat-form-field
          class="names__control"
          appearance="outline"
          color="accent"
        >

          <input
            matInput
            formControlName="firstName"
            required
          >
          <mat-error *ngIf="firstName.errors?.minlength">
            At least 1 letters required.
          </mat-error>
          <mat-error *ngIf="firstName.errors?.maxlength">
            Name can contain only 90 characters.
          </mat-error>
          <mat-error *ngIf="firstName.errors?.pattern">
            Name can't contain numbers.
          </mat-error>
          <mat-error *ngIf="firstName.errors?.required">
            Name is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="names__surname form-field-container">
        <mat-form-field
          class="names__control"
          appearance="outline"
          color="accent"
        >

          <input
            matInput
            formControlName="lastName"
            required
          >
          <mat-error *ngIf="lastName.errors?.minlength">
            At least 1 letters required.
          </mat-error>
          <mat-error *ngIf="lastName.errors?.maxlength">
            Surname can contain only 90 characters.
          </mat-error>
          <mat-error *ngIf="lastName.errors?.pattern">
            Surname can't contain numbers.
          </mat-error>
          <mat-error *ngIf="lastName.errors?.required">
            Surname is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="email form-field-container">

      <mat-form-field
        class="email__control"
        appearance="outline"
        color="accent"
      >
        <input
          matInput
          formControlName="email"
          required
        >
        <mat-error *ngIf="email.errors?.required">
          Email is required
        </mat-error>
        <mat-error *ngIf="email.errors?.email">
          Wrong email format
        </mat-error>
      </mat-form-field>
    </div>

    <div class="terms">
      <mat-checkbox
        [(ngModel)]="terms"
        [ngModelOptions]="{standalone: true}"
        class="terms__checkbox"
      >
      </mat-checkbox>
      <p class="terms__text">
        I have read and agree to the <a class="link">Privacy Policy</a> and <a class="link">Terms & Conditions</a>
      </p>
    </div>

    <button
      mat-ripple
      type="submit"
      class="submit"
      [disabled]="(signingUp$ | async) || !terms || formGroup.invalid"
    >
      SIGN UP
    </button>

  </form>

  <p>
    Already a member?
    <a
      class="member-already"
      routerLink="/login"
    >
      Login
    </a>
  </p>
</div>
