import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { TooltipService } from '../tooltip.service';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input() public tooltipTitle?: string;
  @Input() public tooltipSubtitle?: string;

  private id!: number;

  constructor(
    private tooltipSerivce: TooltipService,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
  ) { }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    // Temporary work around until modal-tooltip integration is implemented in rosalyn-component
    if (!this.tooltipTitle) return
    this.id = Math.random();

    this.tooltipSerivce.addComponent({
      id: this.id,
      ref: this.elementRef,
      title: this.tooltipTitle,
      subtitle: this.tooltipSubtitle,
    });
  }

  @HostListener('mouseleave')
  public onMouseLeave(e: any): void {
    this.destroyComponent();
  }

  @HostListener('click')
  public onMouseClick(): void {
    this.destroyComponent();
  }

  private destroyComponent(): void {
    if (!this.tooltipTitle) return
    this.tooltipSerivce.deleteComponentById(this.id);
    this.changeDetector.detectChanges();
  }
}
