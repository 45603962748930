import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[contentScrollRetainer]',
})
export class ContentScrollRetainerDirective implements OnDestroy {
  private changes: MutationObserver;
  private lastScrollPosition = 0;
  private subscription: Subscription;
  private scrolledContent: Element;

  constructor(private elementRef: ElementRef) {
    this.changes = new MutationObserver(() => this.rollbackScrollPosition());
    this.changes.observe(this.elementRef.nativeElement, { childList: true, subtree: true });

    this.scrolledContent = document.querySelector('.content');

    this.subscription = fromEvent(this.scrolledContent, 'scroll').subscribe(() => {
      this.lastScrollPosition = this.scrolledContent.scrollTop;
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.changes.disconnect();
  }

  private rollbackScrollPosition(): void {
    this.scrolledContent.scrollTo(this.scrolledContent.scrollLeft, this.lastScrollPosition);
  }
}
