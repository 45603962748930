import { ActionReducer } from '@ngrx/store';
import { authActions } from '../actions';
import { AppState } from '../reducers/index';

export function clearStateMetaReducer(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return (state, action): AppState => {
    let newState = state;
    if (action === authActions.logout) {
      newState = undefined;
    }
    return reducer(newState, action);
  };
}
