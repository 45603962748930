<div class="card-container">
  <div class="status">
    <p class="status__text">{{assessment.status}}</p>
  </div>
  <div class="overview">
    <div class="overview__leftside">
      <div class="info">
        <h3 class="info__header">{{assessment.name}}</h3>
        <span class="info__description">{{assessment.description}}</span>
      </div>
      <div
        *ngIf="(assessment.status !== MyAssessmentStatus.FINISHED) && assessment.dueAt"
        class="badge-container"
      >
        <div class="status-badge status-badge--due-on">
          Due on {{assessment.dueAt | date: 'MM/dd/yyyy'}}
        </div>
      </div>
      <div
        *ngIf="assessment.status === MyAssessmentStatus.FINISHED"
        class="badge-container"
      >
        <div class="status-badge">
          Completed: {{assessment.completedAt | date: 'MM/dd/yyyy'}}
        </div>
        <div class="status-badge">
          Result: {{assessment.result.toUpperCase()}}
        </div>
      </div>
    </div>
    <div class="overview__rightside">
      <button
        *ngIf="assessment.result?.toLowerCase() === 'passed'"
        class="certificate"
        (click)="downloadCertificate(assessment.id)"
      >Download certificate
        <img
          class="certificate__icon"
          src="assets/img/icon_download.svg"
        >
      </button>
      <div class="logo">
        <img
          *ngIf="assessment.certificateLogo"
          class="logo__img"
          [src]="assessment.certificateLogo"
        >
      </div>
    </div>
  </div>

  <div>
    <div class="exams-title">
      <p class="exams-title__text">Exams</p>
    </div>

    <table
      mat-table
      class="table"
      [dataSource]="assessment.exams"
    >

      <ng-container matColumnDef="name">
        <th
          class="table__header table-header"
          mat-header-cell
          *matHeaderCellDef
        > Name
        </th>
        <td
          class="table__cell table__cell--name table-cell"
          mat-cell
          *matCellDef="let exam"
        > {{exam.name}} ({{exam.timeInSeconds | secondsToDesc }})
        </td>
      </ng-container>

      <ng-container matColumnDef="purchasedAt">
        <th
          class="table__header table-header table-header--center"
          mat-header-cell
          *matHeaderCellDef
        > Purchase date </th>
        <td
          class="table__cell table-cell table-cell--center"
          mat-cell
          *matCellDef="let exam"
        > {{exam.purchasedAt ? (exam.purchasedAt | date: 'MM/dd/yyyy') : "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th
          class="table__header table-header"
          mat-header-cell
          *matHeaderCellDef
        > Status
        </th>
        <td
          class="table__cell table-cell"
          mat-cell
          *matCellDef="let exam"
        > {{exam.progressStatus}}
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th
          class="table__header table-header table-header--center"
          mat-header-cell
          *matHeaderCellDef
        > Price
        </th>
        <td
          class="table__cell table-cell table-cell--center"
          mat-cell
          *matCellDef="let exam"
        > {{exam.price | price}}
        </td>
      </ng-container>

      <ng-container matColumnDef="finishedAt">
        <th
          class="table__header table-header table-header--center"
          mat-header-cell
          *matHeaderCellDef
        > Finished
        </th>
        <td
          class="table__cell table-cell table-cell--center"
          mat-cell
          *matCellDef="let exam"
        > {{exam.finishedAt ? (exam.finishedAt | date: 'MM/dd/yyyy') : "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="scores">
        <th
          class="table__header table-header table-header--center"
          mat-header-cell
          *matHeaderCellDef
        > Score to pass / max score
        </th>
        <td
          class="table__cell table__cell--score table-cell table-cell--center"
          mat-cell
          *matCellDef="let exam"
        > {{exam.passingScore}} / {{exam.maxScore}}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th
          class="table__header hidden"
          mat-header-cell
          *matHeaderCellDef
        > </th>
        <td
          class="table__cell table__cell--action table-cell table-cell--center hidden"
          mat-cell
          *matCellDef="let exam"
        >
          <button
            *ngIf="!adminMode && exam.progressStatus.toLowerCase() === 'not started'"
            class="action-button"
            mat-ripple
            [disabled]="exam.disabled || launchingExamId"
            (click)="startExam(exam)"
          >
            <span *ngIf="launchingExamId !== exam.id">Start exam</span>
            <mat-icon *ngIf="launchingExamId === exam.id">
              <mat-spinner
                color="accent"
                [diameter]="24"
              ></mat-spinner>
            </mat-icon>
          </button>
          <button
            *ngIf="
                !adminMode &&
                (exam.isRetakeAvailable || exam.progressStatus.toLowerCase() === 'not purchased')
              "
            class="action-button"
            mat-ripple
            [disabled]="true"
          >
            Buy exam
          </button>
          <a
            *ngIf="exam.progressStatus.toLowerCase() === 'passed' || exam.progressStatus.toLowerCase() === 'failed'"
            [routerLink]="adminMode ? ['../assessmentDetails', assessment.id, exam.id] : [assessment.id, exam.id]"
            class="action-button"
            mat-ripple
          >Details
          </a>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns;"
      ></tr>
    </table>
  </div>
</div>
