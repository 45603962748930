
import {
  Component,
  Input,
} from '@angular/core';

export enum BulletItemStatus {
  Current = 'current',
  Upcoming = 'upcoming',
  Completed = 'completed'
}
export interface BulletItem {
  status: BulletItemStatus
  svgIconIdPath: string;
  title: string;
  description: string;
}

@Component({
  selector: 'ros-bullet-item',
  templateUrl: './bullet-item.component.html',
  styleUrls: ['./bullet-item.component.scss'],
})
export class BulletItemComponent {
  @Input() public bulletItem!: BulletItem;

  //https://github.com/storybookjs/storybook/issues/16386
  get bulletItemStatus() {
    return BulletItemStatus;
  }
}
