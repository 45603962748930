import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent {
  @Input() set password(value: string) {
    this.strength = this.checkPasswordStrength(value);
  }

  public strength = 0;

  private checkPasswordStrength(password: string): number {
    if (!password) {
      return 0;
    }

    if (password.length < 8) {
      return 1;
    }

    let strength = 0;

    const numbers = /[0-9]+/.test(password);
    const lowercaseLetters = /[a-z]+/.test(password);
    const uppercaseLetters = /[A-Z]+/.test(password);
    const symbols = /[!@#$%^&*(),.?":{}|<>]/g.test(password);
    const length = password.length >= 12;
    const strengthFactors = [
      numbers,
      lowercaseLetters,
      uppercaseLetters,
      symbols,
      length,
    ];
    strengthFactors.forEach((factor) => {
      if (factor) {
        strength += 1;
      }
    });

    return strength;
  }
}
