import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-error-dialog',
  templateUrl: './basic-error-dialog.component.html',
  styleUrls: ['./basic-error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicErrorDialogComponent {
  public dialogText: string;

  constructor(
    public dialogRef: MatDialogRef<BasicErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dialogText: string },
  ) { }
}
