<div
  class="list-item"
  [ngClass]="{
    'list-item--last': isLast,
    'list-item--inactive': isInactive
  }"
>
  <div class="list-item_content-wrapper">
    <div class="list-item_titles-container">
      <div class="list-item_icon">
        <ros-svg-icon
          [icon]="icon"
          [isTop]="true"
          [type]="isInactive ? 'Disabled' : 'Neutral'"
        ></ros-svg-icon>
      </div>
      <div class="list-item_titles-wrapper">
        <ng-container *ngIf="htmlTitle?.length; then htmlTitleTemplate else defaultTitleTemplate"></ng-container>
        <ng-container *ngIf="htmlSubtitle?.length; then htmlSubtitleTemplate else defaultSubtitleTemplate"></ng-container>
        <p
          *ngIf="additionalMessage"
          class="list-item_message"
          [ngClass]="{'list-item_message--primary': primaryColorMessage }"
        >
          {{ additionalMessage }}
        </p>
      </div>
    </div>
    <div class="list-item_button-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<!-- Title templates -->
<ng-template #defaultTitleTemplate>
  <h6 class="list-item_title">{{ title }}</h6>
</ng-template>

<ng-template #htmlTitleTemplate>
  <h6 class="list-item_title" [innerHTML]="htmlTitle"></h6>
</ng-template>

<!-- Subtitle templates -->
<ng-template #defaultSubtitleTemplate>
  <p class="list-item_subtitle">{{ subtitle }}</p>
</ng-template>

<ng-template #htmlSubtitleTemplate>
  <span class="list-item_subtitle font-bold" *ngIf="detailsSubtitle && detailsSubtitle[0]">{{ detailsSubtitle[1] }} <span class="list-item_details-subtitle-action" (click)="openModal()">{{ detailsSubtitle[2] }}</span></span><h6 class="list-item_subtitle" [innerHTML]="htmlSubtitle"></h6>
</ng-template>

