import { Action, createReducer, on } from '@ngrx/store';
import * as AuthModel from '@models/auth.model';
import { authActions } from '../actions';

export interface AuthState {
  loggingIn: boolean;
  loggedIn: boolean;
  redirectPath: string | null;
  rememberMe: boolean;
  settingPassword: boolean;
  changingPassword: boolean;
  passwordChanged: boolean;
  sendingRecoveryEmail: boolean;
  userData: AuthModel.UserData;
  userTokenData: AuthModel.UserTokenData;
  userTokenDataFetching: boolean;
  editingMyAccount: boolean;
  signingUp: boolean;
}

export const initialState: AuthState = {
  loggingIn: false,
  loggedIn: false,
  redirectPath: null,
  rememberMe: false,
  settingPassword: false,
  changingPassword: false,
  passwordChanged: false,
  sendingRecoveryEmail: false,
  userData: null,
  userTokenData: null,
  userTokenDataFetching: false,
  editingMyAccount: false,
  signingUp: false,
};

const reducer = createReducer(
  initialState,
  on(authActions.login,
    (state, action) => ({
      ...state,
      loggingIn: true,
      redirectPath: action.redirectPath,
      rememberMe: action.loginForm.rememberMe,
    })),
  on(authActions.loginSuccess,
    (state, action) => ({
      ...state,
      loggingIn: false,
      loggedIn: true,
      userData: action.loginResponse.user,
    })),
  on(authActions.loginFail,
    (state) => ({
      ...state,
      loggingIn: false,
      loggedIn: false,
      redirectPath: null,
    })),

  on(authActions.logout,
    (state) => ({
      ...state,
      loggedIn: false,
      userData: null,
    })),
  on(authActions.expiredTokenLogout,
    (state) => ({
      ...state,
      loggedIn: false,
      userData: null,
    })),

  on(authActions.initLogin,
    (state) => ({
      ...state,
      loggingIn: true,
    })),
  on(authActions.initLoginSuccess,
    (state, action) => ({
      ...state,
      loggedIn: true,
      loggingIn: false,
      userData: action.loginResponse.user,
    })),
  on(authActions.initLoginFail,
    (state) => ({
      ...state,
      loggedIn: false,
      loggingIn: false,
    })),

  on(authActions.setPassword,
    (state) => ({
      ...state,
      settingPassword: true,
    })),
  on(authActions.setPasswordSuccess,
    (state) => ({
      ...state,
      settingPassword: false,
    })),
  on(authActions.setPasswordFail,
    (state) => ({
      ...state,
      settingPassword: false,
    })),

  on(authActions.changePassword,
    (state) => ({
      ...state,
      changingPassword: true,
    })),
  on(authActions.changePasswordSuccess,
    (state) => ({
      ...state,
      changingPassword: false,
      passwordChanged: true,
    })),
  on(authActions.changePasswordFail,
    (state) => ({
      ...state,
      changingPassword: false,
    })),
  on(authActions.changePasswordReset,
    (state) => ({
      ...state,
      passwordChanged: false,
    })),

  on(authActions.forgotPassword,
    (state) => ({
      ...state,
      sendingRecoveryEmail: true,
    })),
  on(authActions.forgotPasswordSuccess,
    (state) => ({
      ...state,
      sendingRecoveryEmail: false,
    })),
  on(authActions.forgotPasswordFail,
    (state) => ({
      ...state,
      sendingRecoveryEmail: false,
    })),

  on(authActions.fetchUserTokenData,
    (state) => ({
      ...state,
      userTokenDataFetching: true,
    })),
  on(authActions.fetchUserTokenDataSuccess,
    (state, action) => ({
      ...state,
      userTokenData: action.userTokenData,
      userTokenDataFetching: false,
    })),
  on(authActions.fetchUserTokenDataFail,
    (state) => ({
      ...state,
      userTokenDataFetching: false,
    })),

  on(authActions.editMyAccount,
    (state) => ({
      ...state,
      editingMyAccount: true,
    })),
  on(authActions.editMyAccountSuccess,
    (state, action) => ({
      ...state,
      editingMyAccount: false,
      userData: { ...state.userData, ...action.userData },
    })),
  on(authActions.editMyAccountFail,
    (state) => ({
      ...state,
      editingMyAccount: false,
    })),

  on(authActions.signUp,
    (state) => ({
      ...state,
      signingUp: true,
    })),
  on(authActions.signUpSuccess,
    (state) => ({
      ...state,
      signingUp: false,
    })),
  on(authActions.signUpFail,
    (state) => ({
      ...state,
      signingUp: false,
    })),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
