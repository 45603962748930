import {
  Component,
  Input,
} from '@angular/core';

interface TimelineEntry {
  type: 'Neutral' | 'Info' | 'Error';
  title: string;
  description: string;
  isFlag: boolean;
}

@Component({
  selector: 'ros-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {
  @Input() public entries!: TimelineEntry[];

  constructor() { }
}
