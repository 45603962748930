import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserData } from '@models/auth.model';
import { AnalyticsService } from '@modules/shared/services/analytics/analytics.service';
import { ScriptInjectorService } from '@modules/shared/services/scripts/script-injector.service';
import { ScriptType } from '@modules/shared/services/scripts/scriptType.enum';

@Injectable({
  providedIn: 'root',
})
export class PendoService extends AnalyticsService {
  constructor(scriptInjector: ScriptInjectorService) {
    super(scriptInjector, ScriptType.PENDO);
  }

  public identifyUser(user: UserData): void {
    const { pendo } = window as any;

    if (!pendo) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: `${environment.envName}-${user.id}`,
        role: `${user.roles}`,
        location: user.country,
      },
      account: {
        id: `${environment.envName}-${user.organization.id}`,
      },
    });
  }
}
