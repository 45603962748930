import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}
  public transform(
    price: number,
    currencyCode?: string,
    display?: 'code'|'symbol'|'symbol-narrow'|string|boolean,
    digitsInfo?: string,
    locale?: string,
  ): string {
    return this.currencyPipe.transform(
      price / 100,
      currencyCode,
      display,
      digitsInfo,
      locale,
    );
  }
}
