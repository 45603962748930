import {
  Component,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { emailSuffixValidator } from '@modules/shared/validators/emailSuffix.validator';
import * as AuthModel from '@models/auth.model';

@Component({
  selector: 'app-auth-login-form',
  templateUrl: './auth-login-form.component.html',
  styleUrls: ['./auth-login-form.component.scss'],
})
export class AuthLoginFormComponent {
  @Input() loggingIn: boolean;
  @Output() login = new EventEmitter<AuthModel.LoginCredentials>();

  loginForm: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email, emailSuffixValidator()]],
    password: [null, [Validators.required]],
    rememberMe: [false],
  });

  constructor(private formBuilder: FormBuilder) {
  }

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  submit(): void {
    if (this.loginForm.valid) {
      this.login.emit({ ...this.loginForm.value });
    }
  }
}
