import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicDialogComponent {
  public dialogText: string;

  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dialogText: string, buttonText: string },
  ) { }

  public agree(): void {
    this.dialogRef.close();
  }
}
