<span
  class="status"
  [ngClass]="{
              'status--awaiting': status === SessionStatus.WAITING_FOR_PROCTORING || status === SessionStatus.WAITING_FOR_FINAL_APPROVAL,
              'status--in-progress': status === SessionStatus.PROCTORING_IN_PROGRESS,
              'status--passed': status === SessionStatus.PASSED,
              'status--flagged': status === SessionStatus.FLAGGED
            }"
>
  <ng-content></ng-content>
</span>
