import { Component } from '@angular/core';
import { TooltipService } from '../tooltip.service';

@Component({
  selector: 'ros-tooltip-container',
  templateUrl: './tooltip-container.component.html',
  styleUrls: ['./tooltip-container.component.scss'],
})
export class TooltipContainerComponent {
  constructor(public tooltipService: TooltipService) { }
}
