import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AppStoreModule } from '@store/app-store.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemingService } from 'app/theming.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { CoreModule } from './modules/core/core.module';
import { MaterialModule } from './modules/material/material.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppStoreModule,
    CoreModule,
    MaterialModule,
    AuthModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AuthModule,
    { provide: 'Window', useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: (themingService: ThemingService) => async(): Promise<void> => themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
