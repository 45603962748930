import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { LearnersService } from '@services/learners.service';
import { ErrorResponse } from '@models/response.model';
import { AppState } from '@store/reducers';
import { getCartProducts, getProductDetails } from '@store/selectors';
import { BasicSnackBarService } from '@modules/shared/services/basic-snackbar.service';
import { Router } from '@angular/router';
import { learnersActions } from '../actions';

@Injectable()
export class LearnersEffects {
  constructor(
    private actions$: Actions,
    private snackBar: BasicSnackBarService,
    private learnersService: LearnersService,
    private store: Store<AppState>,
    private router: Router,
  ) { }

  public $fetchMyAssessments = createEffect(() => this.actions$.pipe(
    ofType(learnersActions.fetchMyAssessments),
    switchMap(({ filters }) => this.learnersService.fetchMyAssessments(filters)
      .pipe(
        map((assessments) => learnersActions.fetchMyAssessmentsSuccess({ assessments })),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message);
          return of(learnersActions.fetchMyAssessmentsFail());
        }),
      )),
  ));

  public $fetchCountries = createEffect(() => this.actions$.pipe(
    ofType(learnersActions.fetchCountries),
    switchMap(() => this.learnersService.fetchCountries()
      .pipe(
        map((countries) => learnersActions.fetchCountriesSuccess({ countries })),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message);
          return of(learnersActions.fetchCountriesFail());
        }),
      )),
  ));

  public $fetchProducts = createEffect(() => this.actions$.pipe(
    ofType(learnersActions.fetchProducts),
    switchMap(({ categoryId, subcategoryId }) => this.learnersService.fetchProducts(categoryId, subcategoryId)
      .pipe(
        map((products) => learnersActions.fetchProductsSuccess({ products })),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message);
          return of(learnersActions.fetchProductsFail());
        }),
      )),
  ));

  public $fetchProductDetails = createEffect(() => this.actions$.pipe(
    ofType(learnersActions.fetchProductDetails),
    switchMap(({ reference }) => this.learnersService.fetchProductDetails(reference)
      .pipe(
        map((productDetails) => learnersActions.fetchProductDetailsSuccess({ productDetails })),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.router.navigate(['/']);
          this.snackBar.open(error.message);
          return of(learnersActions.fetchProductDetailsFail());
        }),
      )),
  ));

  public $buyProduct = createEffect(() => this.actions$.pipe(
    ofType(learnersActions.buyProduct),
    switchMap(({ reference }) => this.learnersService.buyProduct(reference)
      .pipe(
        map(() => {
          this.snackBar.open('Selected exams have been purchased');
          return learnersActions.buyProductSuccess();
        }),
        catchError(({ error }: { error: ErrorResponse }) => {
          this.snackBar.open(error.message);
          return of(learnersActions.buyProductFail());
        }),
      )),
  ));

  public $addProductToCart = createEffect(() => this.actions$.pipe(
    ofType(learnersActions.addProductToCart),
    withLatestFrom(this.store.select(getCartProducts)),
    withLatestFrom(this.store.select(getProductDetails)),
    switchMap(([[{ examIds, productId }, cartProducts], productDetails]) => {
      const addedProductExams = cartProducts[productId];
      const duplicateExamId = addedProductExams?.length
        ? examIds.find((productExamId) => addedProductExams.includes(productExamId))
        : null;

      if (duplicateExamId) {
        const completeExam = productDetails.exams.find((exam) => exam.id === duplicateExamId);

        this.snackBar.open(`Exam ${completeExam.name} has already been added to the cart`);
        return of(learnersActions.addProductToCartFail());
      }

      this.snackBar.open('Selected exams have been added to the cart');
      return of(learnersActions.addProductToCartSuccess({ examIds, productId }));
    }),
  ));
}
