import { FormGroup } from '@angular/forms';

export function confirmPasswords(group: FormGroup) {
  const password = group.get('password');
  const confirmPassword = group.get('confirmPassword');

  if (confirmPassword.value && (password.value !== confirmPassword.value)) {
    confirmPassword.setErrors({ differentPasswords: true });
  } else if (confirmPassword.value) {
    confirmPassword.setErrors(null);
  }
}
