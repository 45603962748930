<div class="cropper">
  <h1 class="cropper__header">Crop image</h1>
  <div class="cropper__container">
    <image-cropper
      *ngIf="imageLoaded"
      class="cropper__image-cropper"
      format="jpeg"
      resizeToWidth="1024"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [onlyScaleDown]="true"
      [aspectRatio]="aspectRatio"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>

  <button
    mat-button
    class="cropper__done"
    (click)="done()"
  >Done
  </button>
</div>
