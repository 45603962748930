import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
  setSessionStorage(name: string, value: any): void {
    sessionStorage.setItem(name, JSON.stringify(value));
  }

  getSessionStorage(name: string): any {
    try {
      return JSON.parse(sessionStorage.getItem(name));
    } catch (error) {
      return null;
    }
  }

  cleanSessionStorage(): void {
    sessionStorage.clear();
  }
}
